import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenericObject } from '@utils/models/Types';

@Injectable({
  providedIn: 'root'
})
export class TranslationWrapperService {
  constructor(private _translateService: TranslateService) {}

  translateKey(key: string, params?: GenericObject): string | any {
    return this._translateService.instant(key, params);
  }
}
