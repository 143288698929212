import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'next-input',
  templateUrl: './next-input.component.html',
  styleUrl: './next-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextInputComponent {
  @Input() name: string;
  @Input() control: any;
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() maxlength: number = 255;
}
