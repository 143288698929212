export enum NextTableProps {
  PAGINATOR_ROWS = 'rows',
  PAGINATOR_FIRST = 'first'
}

export enum TableFieldProps {
  FIELD = 'field',
  HEADER = 'header',
  IS_FILTER = 'isFilter',
  IS_SORTABLE = 'isSortable',
  SORTABLE_FIELD = 'sortableField',
  IS_TOOLTIP = 'isTooltip',
  IS_HIDDEN = 'isHidden',
  FIELD_TYPE = 'types',
  PIPE_LIST = 'pipeList',
  ACTION = 'action',
  SEPARATOR = 'separator',
  ALIGN = 'align',
  FILTER_TYPE = 'filterType',
  STATUS_MAP = 'statusMap',
  TEMPLATE_TYPE = 'templateType',
  WIDTH = 'width',
  MENU_OPTION_LIST_KEY = 'menuOptionListKey',
  ALIGN_BUTTON = 'alignButton',
  MENU_OPTION_LIST = 'menuOptionList',
  TOOLTIP = 'tooltip',
  FILTER_FIELD = 'filterField'
}

export enum GenericTableColumnProps {
  VIEW = 'view',
  ACTIONS = 'actions',
  MENU_LIST = 'menuList',
  MENU_LIST_PENDING = 'menuListPending',
  MENU_LIST_WAITING = 'menuListWaiting',
  MENU_LIST_REJECTED = 'menuListRejected'
}

export enum NextFilterColumnConfigProps {
  FIELD = 'field',
  MATCH_MODE = 'matchMode',
  TYPE = 'type'
}

export enum NextFilterType {
  MULTI_SELECTION = 'multiSelection',
  DATE = 'date',
  MULTI_FIELD = 'multiField'
}

export enum BadgePermissionProps {
  CONTROL_KEY = 'controlKey',
  LABEL_KEY = 'valueKey',
  COLOR_TYPE = 'colorType',
  DEFAULT_VALUE = 'defaultValue'
}

export enum TableTestId {
  SHOW_ACTION_BUTTON = 'table__show-action'
}
