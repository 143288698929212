<div class="next-card"
     [class.next-card--border]="hasBorder"
     [class.next-card--background]="hasBackgroundColor"
     [class.next-card--none-border-radius]="hasNoneBorderRadius"
     [class.next-card--horizontal]="isHorizontalContent"
>
  <div class="next-card__body">
    <ng-container *ngIf="(header || headerKey); else headerTemplate">
      <div class="next-card__title">
        {{header || (headerKey! | translate)}}
      </div>
    </ng-container>
    <ng-template #headerTemplate>
      <ng-content select="[headerTemplate]"></ng-content>
    </ng-template>
    <div class="next-card__content"
         [class.next-card--inner-border]="hasInnerBorder">
      <div class="group-details__container">
        <div *ngIf="subHeader || subHeaderKey" class="next-card__subheader">
          {{subHeader || (subHeaderKey! | translate)}}
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
