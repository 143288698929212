import { NextValueHelper } from './next-value.helper';
import { NextObjectHelper } from './next-object.helper';
import { GenericFunction, GenericObject, PrimitiveValue } from '@utils/models/Types';
import { ValueMatchOptions } from '@utils/models/Value';
import { FilterItemMatchConfig, FilterListConfig, RecursiveIteratorConfig, SortConfig } from '@utils/models/Array';

export class NextArrayHelper {
  /**
   * Validates if array has items
   *
   * @param arr
   * @returns boolean
   *
   */
  static isListFilled(arr?: any[]): boolean {
    // @ts-expect-error From Common
    return arr?.length > 0;
  }

  /**
   * Validates if array has argument item
   *
   * @param arr PrimitiveValue
   * @param value
   * @param options
   * @returns boolean
   *
   */
  static isElementInList(arr: PrimitiveValue[] = [], value: PrimitiveValue, options?: ValueMatchOptions): boolean {
    return arr.some(targetItem => NextValueHelper.isValueEqual(value, targetItem, options));
  }

  /**
   *  Filter elements in argument from list based on config
   *
   * @param list
   * @param filterConfig FilterConfig[] | FilterConfig
   * @param filterListConfig FilterListConfig
   *
   * @returns list
   *
   */
  static filterListByConfig(
    list: any[] = [],
    filterConfig: FilterItemMatchConfig[] | FilterItemMatchConfig = [],
    filterListConfig: FilterListConfig = {}
  ): any[] {
    const filterConfigList = NextArrayHelper.buildArrayFromValue(filterConfig);
    return list.filter((listItem: any) => {
      const { getValueCb, targetValueMap } = filterListConfig;
      const valueObj = getValueCb ? getValueCb(listItem) : listItem;
      return filterConfigList.every(filterItem => {
        const filterItemExtension = { ...filterItem };
        const keyProp = filterItem.propertyKey;

        if (targetValueMap && keyProp) {
          Object.assign(filterItemExtension, {
            targetValues: NextObjectHelper.getPropertyFromObject(targetValueMap, filterItem.propertyKey)
          });
        }

        const hasMatch = NextValueHelper.valueMatchByConfig(valueObj, filterItemExtension);
        // @ts-expect-error TODO From Common
        const children = listItem[filterListConfig.childrenPropertyKey];

        if (!hasMatch && children) {
          const childrenMatch = NextArrayHelper.filterListByConfig(children, filterConfig, filterListConfig);

          return NextArrayHelper.isListFilled(childrenMatch);
        }
        return hasMatch;
      });
    });
  }

  /**
   *  Builds array from value. If value is an array, returns the same.
   *
   * @param val
   * @param defaultToEmpty if true and val is undefined, returns empty list
   *
   * @returns list
   *
   */
  static buildArrayFromValue(val?: any, defaultToEmpty = false): any[] {
    if (NextValueHelper.isValueDefined(val)) {
      return Array.isArray(val) ? val : [val];
    }

    return defaultToEmpty ? [] : val;
  }

  /**
   *  Given two lists, validates if there is an intersection
   *
   * @param list1
   * @param list2
   *
   * @returns boolean
   *
   */
  static hasIntersection(list1: any[] = [], list2: any[] = []): boolean {
    return list1.some(v => list2.indexOf(v) !== -1);
  }

  /**
   *  Given two lists, returns its intersection
   *
   * @param list1
   * @param list2
   *
   * @returns any[]
   *
   */
  static getListIntersection(list1: any[] = [], list2: any[] = []): any[] {
    return list1.filter(x => list2.includes(x));
  }

  /**
   *  Given two lists, returns its difference
   *
   * @param list1
   * @param list2
   *
   * @returns any[]
   *
   */
  static getListDifference(list1: any[] = [], list2: any[] = []): any[] {
    return list1.filter(x => !list2.includes(x));
  }

  /**
   *  Given two lists, returns its union
   *
   * @param list1
   * @param list2
   *
   * @returns any[]
   *
   */
  static getListUnion(list1: any[] = [], list2: any[] = []): any[] {
    return Array.from(new Set([...list1, ...list2]));
  }

  /**
   *  Applies method to list elements by pair
   *
   * @param arr
   * @param cb Method to execute
   *
   */
  static pairwise(arr: any[] = [], cb: GenericFunction) {
    for (let i = 0; i < arr.length - 1; i++) {
      cb(arr[i], arr[i + 1]);
    }
  }

  /**
   *  Returns mapped list of tuples by index
   *
   * @param arr
   *
   * @returns tuple list
   *
   */
  static pairwiseMap(arr: any[]): any[] {
    const res: any = [];

    NextArrayHelper.pairwise(arr, (a, b) => {
      res.push([a, b]);
    });

    return res;
  }

  /**
   *  Returns object from array, indexed by property
   *
   * @param arrayArg
   * @param keyProperty Key for index
   * @param valueProperty If defined will return prop value, otherwise all the object
   *
   * @returns Object
   *
   */
  static indexArrayByProperty(arrayArg: any[], keyProperty: string | number, valueProperty?: string | number): any {
    const array = NextValueHelper.defaultValue(arrayArg, []);
    return array.reduce((acc: { [x: string]: any }, item: { [x: string]: any }) => {
      acc[item[keyProperty]] = valueProperty ? item[valueProperty] : item;
      return acc;
    }, {});
  }

  static calculatePropertySumFromList(property: string, list: GenericObject[] = []): number {
    return list.reduce((acc, item) => acc + (item[property] || 0), 0);
  }

  static _calculateValuesSortOrder(
    item1: { [x: string]: any },
    item2: { [x: string]: any },
    sortConfig: SortConfig
  ): number {
    const value1 = item1[sortConfig.key];
    const value2 = item2[sortConfig.key];
    const isDesc = sortConfig.isDescending;
    const factor = isDesc ? -1 : 1;
    let res = 0;

    if (value1 === value2) {
      return 0;
    }

    res = value1 > value2 ? 1 : -1;

    return res * factor;
  }

  static sortByConfig(list: any[], configList: SortConfig[] = []): any[] {
    return list.sort((itemA, itemB) => {
      let sortFactor = 0;

      configList.some(configItem => {
        sortFactor = NextArrayHelper._calculateValuesSortOrder(itemA, itemB, configItem);

        return !!sortFactor;
      });

      return sortFactor;
    });
  }

  static iterateRecursiveList(itemList: GenericObject[], config: RecursiveIteratorConfig, acc = []): void {
    itemList.some((item: GenericObject) => {
      const cycleBreakCb = config.cycleBreakCb;
      const itemChildren = item[config.childrenPropertyKey];
      const itemAcc = config.itemCb(item, acc);

      if (itemChildren) {
        const childAcc: any = [];

        if (itemAcc) {
          Object.assign(itemAcc, {
            [config.childrenPropertyKey]: childAcc
          });
        }

        NextArrayHelper.iterateRecursiveList(itemChildren, config, childAcc);
      }

      if (cycleBreakCb) {
        return cycleBreakCb(item);
      }

      return false;
    });
  }

  static moveItemToIndex(array: any[], fromIndex: number, toIndex = array.length - 1) {
    const arrayCopy = NextObjectHelper.deepClone(array);
    const element = arrayCopy.splice(fromIndex, 1)[0];
    arrayCopy.splice(toIndex, 0, element);

    return arrayCopy;
  }

  static getListDifferenceById(list1: any[] = [], list2: any[] = []) {
    return list1.filter(o1 => !list2.some(o2 => o1.id === o2.id));
  }
}
