import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateWrapperPipe } from './pipes/translate-wrapper.pipe';

@NgModule({
  declarations: [TranslateWrapperPipe],
  exports: [TranslateWrapperPipe],
  imports: [CommonModule]
})
export class AppTranslationModule {}
