{
  "name": "wwtf-ng-ui",
  "version": "0.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-local": "ng serve --configuration=local",
    "start-development": "ng serve --configuration=development",
    "start:prod": "ng serve --configuration=production",
    "start-local-development": "ng serve --configuration=local-development",
    "build": "ng build",
    "build:prod": "ng build --configuration=production",
    "build:qa": "ng build --configuration=qa",
    "build:dev": "ng build --configuration=development",
    "build:local-development": "ng build --configuration=local-development",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "format": "prettier --config .prettierrc.js \"src/**/*{.ts,.json,.html}\" --write",
    "lint": "eslint . --ext .ts --quiet --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "dayjs": "^1.11.10",
    "file-saver": "^2.0.5",
    "jwt-decode": "^4.0.0",
    "lodash.clonedeep": "^4.5.0",
    "lodash.merge": "^4.6.2",
    "moment": "^2.30.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.3.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
    "xlsx-populate": "^1.21.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.8",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.0.8",
    "@angular/compiler-cli": "^17.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.14.202",
    "@types/node": "^20.11.16",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "husky": "^8.0.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.2.4",
    "prettier-eslint": "^16.2.0",
    "typescript": "~5.2.2"
  }
}
