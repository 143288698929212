<div class="data-card"
    [class.data-card--border]="hasBorderCard"
    [class.data-card--border-radius]="hasBorderCardRadius"
    [class.data-card--horizontal-content]="isHorizontalContent"
    [class.data-card--horizontal-data]="isHorizontalData"
    [class.cards-2]="configList?.length === 2">
    <h3 *ngIf="subTitle || subTitleKey" class="data-card__subtitle">{{subTitle || (subTitleKey! | translate)}}</h3>
  <data-title *ngFor="let config of dataTitleConfigList"
              [data]="dataObject"
              [dataRef]="dataObjectRef"
              [dataPath]="config[DataTitleProps.PATH]!"
              [pipeList]="config[DataTitleProps.PIPE_LIST]!"
              [title]="config.title!"
              [titleKey]="config.titleKey!"
              [isDataBold]="config.isDataBold!"
              [fieldHidden]="config.fieldHidden!"
              [dataFontSize]="config.dataFontSize!"
              [isHorizontalData]=isHorizontalData>
  </data-title>
</div>
