import { UserManagerProps, UserPermission, UserStatus, UserRole } from '../enums/user.enum';
import { ColumnSize, FieldType, PermissionControlConfig, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { BadgePermissionProps, NextFilterType, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { ColorType } from 'app/shared/enums/color.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { StatusConfigProps } from 'app/core/enums/status.enum';
import { StatusMap } from 'app/core/types/status.type';
import { GenericObject } from '@utils/models/Types';

export class UserManagerConstant {
  static globalFilter = [UserManagerProps.GIVEN_NAME, UserManagerProps.CDSID];

  static permissionConfig: PermissionControlConfig[] = [
    {
      [BadgePermissionProps.CONTROL_KEY]: UserPermission.READ,
      [BadgePermissionProps.LABEL_KEY]: 'USER.PERMISSION.READ',
      [BadgePermissionProps.COLOR_TYPE]: ColorType.SUCCESS
    },
    {
      [BadgePermissionProps.CONTROL_KEY]: UserPermission.WRITE,
      [BadgePermissionProps.LABEL_KEY]: 'USER.PERMISSION.WRITE',
      [BadgePermissionProps.COLOR_TYPE]: ColorType.DANGER
    },
    {
      [BadgePermissionProps.CONTROL_KEY]: UserPermission.APPROVE,
      [BadgePermissionProps.LABEL_KEY]: 'USER.PERMISSION.APPROVE',
      [BadgePermissionProps.COLOR_TYPE]: ColorType.CAUTION
    },
    {
      [BadgePermissionProps.CONTROL_KEY]: UserPermission.CREATE_MODIFY,
      [BadgePermissionProps.LABEL_KEY]: 'USER.PERMISSION.CREATE_MODIFY',
      [BadgePermissionProps.COLOR_TYPE]: ColorType.CAUTION
    }
  ];

  
  static statusMapConfig: GenericObject<StatusMap> = {
    [UserStatus.ACTIVE]: {
      [StatusConfigProps.COLOR]: ColorType.SUCCESS,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.ACTIVE'
    },
    [UserStatus.SUSPENDED]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.SUSPENDED'
    },
    [UserStatus.CLOSED]: {
      [StatusConfigProps.COLOR]: ColorType.DANGER,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.CLOSED'
    },
    [UserStatus.WAITING]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.WAITING_APPROVAL'
    },
    [UserStatus.PENDING]: {
      [StatusConfigProps.COLOR]: ColorType.PURPLE,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.PENDING'
    },
    [UserStatus.REVIEWED]: {
      [StatusConfigProps.COLOR]: ColorType.BLUE,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.REVIEWED'
    }
  };
  
  static tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: UserManagerProps.USER_NAME,
      [TableFieldProps.HEADER]: 'USER.FIELDS.USER_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: UserManagerProps.CDSID,
      [TableFieldProps.HEADER]: 'USER.FIELDS.USER_ID',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: UserManagerProps.ROLES,
      [TableFieldProps.HEADER]: 'USER.FIELDS.ROLE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.ARRAY],
      [TableFieldProps.SEPARATOR]: ', '
    },
    {
      [TableFieldProps.FIELD]: UserManagerProps.CREATED_TIMESTAMP,
      [TableFieldProps.HEADER]: 'USER.FIELDS.CREATION_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
    },
    {
      [TableFieldProps.FIELD]: UserManagerProps.LAST_MODIFIED_TIMESTAMP,
      [TableFieldProps.HEADER]: 'USER.FIELDS.LAST_MODIFIED_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
    },
    {
      [TableFieldProps.FIELD]: UserManagerProps.STATUS,
      [TableFieldProps.HEADER]: 'USER.FIELDS.STATUS',
      [TableFieldProps.STATUS_MAP]: UserManagerConstant.statusMapConfig,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS
    },
    {
      [TableFieldProps.FIELD]: UserManagerProps.PARAMETERS,
      [TableFieldProps.HEADER]: 'USER.FIELDS.PARAMETERS',
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN_BUTTON]: LinkButtonPosition.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static statusConfig: any = {
    [UserStatus.ALL]: 'TABLE.STATUS.ALL',
    [UserStatus.ACTIVE]: 'TABLE.STATUS.ACTIVE',
    [UserStatus.SUSPENDED]: 'TABLE.STATUS.SUSPENDED',
    [UserStatus.WAITING]: 'TABLE.STATUS.WAITING_APPROVAL',
    [UserStatus.PENDING]: 'TABLE.STATUS.PENDING',
    [UserStatus.REVIEWED]: 'TABLE.STATUS.REVIEWED'
  };

  static colorStatusConfig: any = {
    [UserStatus.ALL]: ColorType.NEUTRAL,
    [UserStatus.ACTIVE]: ColorType.SUCCESS,
    [UserStatus.SUSPENDED]: ColorType.DANGER,
    [UserStatus.WAITING]: ColorType.CAUTION,
    [UserStatus.PENDING]: ColorType.PURPLE,
    [UserStatus.REVIEWED]: ColorType.BLUE
  };

  static userRoleConfig: any = {
    [UserRole.OPERATIONS]: 'USER.ROLES.OPERATIONS',
    [UserRole.GENERAL_OVERVIEW]: 'USER.ROLES.GENERAL_OVERVIEW',
    [UserRole.WWTF_ACCOUNTING]: 'USER.ROLES.WWTF_ACCOUNTING',
    [UserRole.OPERATIONS_SUPERVISOR]: 'USER.ROLES.OPERATIONS_SUPERVISOR',
    [UserRole.CAM]: 'USER.ROLES.CAM',
    [UserRole.ACCOUNT_MANAGER]: 'USER.ROLES.ACCOUNT_MANAGER'
  }
}
