import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NextNumberHelper } from '@utils/core/next-number.helper';
import { ValidationConstant } from '@constants/validation.constant';
import { PatternType } from '@utils/enums/patterns.enum';
import { PatternConstant } from '../../core/constants/pattern.constant';
import { NextFormsConstant } from '@constants/forms.constant';
import { NextInputValidationKey } from '@utils/enums/validators.enum';
import { NextDateHelper } from '@utils/core/next-date.helper';
import { TimeCompareReference } from '../../core/enums/date.enum';
import { GuarantorProps, InsurerProps, SecurityType } from '@pages/distributor/enums/securities.enum';

export class NextInputValidators {
  static requiredNoBlank(control: AbstractControl): ValidationErrors | null {
    if (Validators.required(control) || !new RegExp(PatternType.NO_EMPTY).test(control.value)) {
      return { [NextInputValidationKey.REQUIRED_NO_SPACES]: true };
    }
    return null;
  }

  static minLengthNoSpaces(min: number): ValidatorFn {
    // @ts-expect-error From Common
    return (control: FormControl): ValidationErrors | null => {
      const value = control.value;
      const isValid = NextValueHelper.isValueEmpty(value) || `${value}`.trim().length >= min;
      const error = {
        [NextInputValidationKey.MIN_LENGTH_NO_SPACES]: {
          requiredLength: min
        }
      };

      return isValid ? null : error;
    };
  }

  static maxLengthWithSpaces(min: number): ValidatorFn {
    // @ts-expect-error From Common
    return (control: FormControl): ValidationErrors | null => {
      const value = control.value;
      const isValid = NextValueHelper.isValueEmpty(value) || `${value}`.length <= min;
      const error = {
        [NextInputValidationKey.MIN_LENGTH]: {
          requiredLength: min
        }
      };

      return isValid ? null : error;
    };
  }
  static securitiesAmount(referenceControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const referenceAmount = referenceControl.value;
      const guarantorFormArray = NextValueHelper.defaultValue(control.get(SecurityType.GUARANTORS)?.value, []);
      const insurerFormArray = NextValueHelper.defaultValue(control.get(SecurityType.INSURERS)?.value, []);
      const guarantorAmount = NextNumberHelper.amountReduce(guarantorFormArray, GuarantorProps.AMOUNT);
      const insurerAmount = NextNumberHelper.amountReduce(insurerFormArray, InsurerProps.AMOUNT);
      const securitiesAmount: any[] = insurerAmount + guarantorAmount;

      if (NextNumberHelper.isConvertibleToNumber(securitiesAmount) && securitiesAmount >= referenceAmount) {
        return null;
      } else {
        return { [NextInputValidationKey.REQUIRED_SECURITIES]: true };
      }
    };
  }

  static amountListEqualsToReferenceControl(
    controlPath: any[],
    amountProp: any,
    fieldName: string,
    referenceFieldName: string
  ): ValidatorFn {
    return (controlArray: AbstractControl): ValidationErrors | null => {
      const referenceControl = controlArray.root.get(controlPath);
      if (referenceControl) {
        if (!NextNumberHelper.isConvertibleToNumber(referenceControl.value)) {
          return null;
        }
        const amount: any[] = controlArray.value;
        const accValue = NextNumberHelper.amountReduce(amount, amountProp);

        if (NextNumberHelper.isConvertibleToNumber(accValue) && accValue === referenceControl.value) {
          return null;
        } else {
          return {
            [NextInputValidationKey.AMOUNT_NOT_MATCH]: { fieldName, referenceFieldName }
          };
        }
      }
      return null;
    };
  }

  static dateComparedToReferenceNotBe(timeReference: TimeCompareReference, controlPath: any[], fieldName: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const referenceControl = control.root.get(controlPath);
      if (referenceControl) {
        const referenceDate = referenceControl.value;
        const currentDate = control.value;
        if (!NextDateHelper.isValidDate(referenceDate)) {
          return null;
        }
        const itAsserts = NextDateHelper.isDateComparedToReference(timeReference, currentDate, referenceDate);

        if (itAsserts) {
          return {
            [ValidationConstant.dateComparedValidationKeyErrorByTimeReference[timeReference]]: {
              fieldName,
              date: referenceDate
            }
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  static maxTwoDecimals(control: AbstractControl): ValidationErrors | null {
    const regEx = new RegExp(PatternType.NUMBER_MAX_TWO_DECIMALS);
    const value = control.value;

    const isValid = NextValueHelper.isValueEmpty(value) || regEx.test(value);

    const error = { [NextInputValidationKey.MAX_TWO_DECIMALS]: true };

    return isValid ? null : error;
  }

  static allowNumberDotAndComma(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const regEx = new RegExp(PatternType.NUMBER_DOTS_COMMAS);
    const isValid = NextValueHelper.isValueEmpty(value) || regEx.test(value);

    const error = { [NextInputValidationKey.AMOUNT_VALIDATOR]: true };

    return isValid ? null : error;
  }

  static greaterThan(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (NextValueHelper.isValueEmpty(value)) {
        return null;
      }
      const valueWithoutComma = NextNumberHelper.swapCommaByDot(value);
      const valueNum = parseFloat(valueWithoutComma);

      return !isNaN(valueNum) && !(valueNum > min)
        ? { [NextInputValidationKey.GREATER_THAN]: { min, actual: value } }
        : null;
    };
  }

  static amountGenericValidation(control: AbstractControl): ValidationErrors | null {
    const err =
      NextInputValidators.allowNumberDotAndComma(control) ||
      Validators.maxLength(18)(control) ||
      NextInputValidators.maxTwoDecimals(control) ||
      NextInputValidators.greaterThan(0)(control);

    return err;
  }

  static numeric(control: AbstractControl): ValidationErrors | null {
    const error = Validators.pattern(PatternType.NUMBER)(control);

    return !!error ? { [NextInputValidationKey.NUMERIC]: true } : null;
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    const error =
      Validators.pattern(PatternType.PHONE)(control) ||
      Validators.maxLength(ValidationConstant.phoneNumberMaxLength)(control);

    return !!error ? { [NextInputValidationKey.PHONE_NO]: true } : null;
  }

  static fixedLength(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const err = Validators.minLength(length)(control) || Validators.maxLength(length)(control);

      return err;
    };
  }

  static notAllStringItemsEqual(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (
      typeof value !== 'string' ||
      NextValueHelper.isValueEmpty(value) ||
      value.length < 2 ||
      new Set(value).size > 1
    ) {
      return null;
    }

    return { [NextInputValidationKey.ALL_STRING_ITEMS_EQUAL]: true };
  }

  static noSpecialChars(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const exp = new RegExp(PatternConstant.noSpecialCharsAccountHolder);

    const isValid = !exp.test(value);

    return isValid ? null : { [NextInputValidationKey.SPECIAL_CHARS]: true };
  }

  static patternValidation(pattern: string, customMsgKey?: NextInputValidationKey): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const patternError = Validators.pattern(pattern)(control);
      let error = null;

      if (!!patternError) {
        error = {
          [customMsgKey ? customMsgKey : NextInputValidationKey.CUSTOM_PATTERN]: {
            ...patternError['pattern'],
            patternKey: NextFormsConstant.patternErrorKeyMapping[pattern]
          }
        };
      }

      return error;
    };
  }
}
