import { Pipe, PipeTransform } from '@angular/core';
import { PipeConfig } from '../../../core/types/pipes.type';
import { DynamicPipe } from '@pipes/common/dynamic.pipe';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NextObjectHelper } from '@utils/core/next-object.helper';

@Pipe({
  name: 'pipeListFormat',
  standalone: true
})
export class PipeListFormatPipe implements PipeTransform {
  constructor(private _dynamicPipe: DynamicPipe) {}
  transform(valueToFormat: any, pipeList: PipeConfig[], dataObject: any = {}): any {
    let formattedValue = valueToFormat;
    pipeList.forEach((pipeConfig: PipeConfig) => {
      const computedArgs: any = [];
      const args = NextValueHelper.defaultValue(pipeConfig.args, []);

      args.forEach((argItem: any) => {
        computedArgs.push(
          argItem.fromValue ? NextObjectHelper.getPropertyFromObject(dataObject, argItem.fromValue) : argItem
        );
      });

      formattedValue = this._dynamicPipe.transform(
        formattedValue,
        pipeConfig.pipe,
        computedArgs.length ? computedArgs : undefined
      );
    });
    return formattedValue;
  }
}
