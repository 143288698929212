import { NextArrayHelper } from './next-array.helper';
import { NextObjectHelper } from './next-object.helper';
import { NextStringHelper } from './next-string.helper';
import { ValueMatchOptions } from '@utils/models/Value';
import { PrimitiveValue } from '@utils/models/Types';
import { ValueMatchType } from '@utils/enums/value.enum';
import { FilterItemMatchConfig } from '@utils/models/Array';

export class NextValueHelper {
  static readonly stringToBoolMap = {
    true: true,
    false: false
  };

  static defaultValue(value: any, defaultValue?: any): any {
    return value ?? defaultValue;
  }

  static isValueDefined(val: any): boolean {
    const ctrl = {};

    return (val ?? ctrl) !== ctrl;
  }

  static isValueEqual(value: PrimitiveValue, target: PrimitiveValue, options: ValueMatchOptions = {}): boolean {
    let compareValue = value;
    let compareTarget = target;
    const stringOptions = { isLowerCase: !options.isCaseSensitive };

    if (!options.isCaseSensitive) {
      // @ts-expect-error TODO From Common
      compareValue = NextStringHelper.convertToString(value, stringOptions);
      // @ts-expect-error TODO From Common
      compareTarget = NextStringHelper.convertToString(target, stringOptions);
    }

    return compareValue === compareTarget;
  }

  static isEqualIfDefined(val: any, target: any): boolean {
    return !NextValueHelper.isValueDefined(val) || val === target;
  }

  static isValueEmpty(val: any): boolean {
    return (
      !NextValueHelper.isValueDefined(val) || val === '' || (Array.isArray(val) && !NextArrayHelper.isListFilled(val))
    );
  }

  static isSubsetInValue(value: PrimitiveValue, subset: PrimitiveValue, options: ValueMatchOptions = {}): boolean {
    const stringOptions = { isLowerCase: !options.isCaseSensitive };
    const valueStr = NextStringHelper.convertToString(value, stringOptions);
    const subsetStr = NextStringHelper.convertToString(subset, stringOptions);

    // @ts-expect-error TODO From Common
    return !!valueStr?.includes(subsetStr);
  }

  static valueMatchByConfig(valueArg: any, matchConfig: FilterItemMatchConfig): boolean {
    const { matchType = ValueMatchType.EQUALS, isExclude, targetValues, propertyKey } = matchConfig;
    const hasProperty = !!propertyKey;

    if (!NextValueHelper.isValueDefined(targetValues)) {
      return true;
    }

    const value: PrimitiveValue =
      hasProperty && NextObjectHelper.isObject(valueArg)
        ? NextObjectHelper.getPropertyFromObject(valueArg, propertyKey)
        : valueArg;
    const targetArray = NextArrayHelper.buildArrayFromValue(targetValues);

    const matchCbByType: any = {
      [ValueMatchType.EQUALS]: () => NextArrayHelper.isElementInList(targetArray, value, matchConfig),
      [ValueMatchType.CONTAINS]: () =>
        targetArray.some((targetItem: any) => NextValueHelper.isSubsetInValue(value, targetItem, matchConfig))
    };

    const hasMatch = matchCbByType[matchType]();

    return (isExclude && !hasMatch) || (!isExclude && hasMatch);
  }

  static ternaryOperator(condition: boolean, trueVal: any, falseVal: any): any {
    return condition ? trueVal : falseVal;
  }

  static isValuePresent(val: any) {
    // Check for null, undefined
    if (val === null || val === undefined) return false;
    
    // Check for empty string or array
    if (typeof val === 'string' || Array.isArray(val)) return val.length > 0;
    
    // Check for empty object
    if (typeof val === 'object') return Object.keys(val).length > 0;
    
    // For numbers, boolean, and others
    return true;
  }
}
