import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges } from '@angular/core';

import { AbstractSelectComponent } from '@components/atoms/form-inputs/components/abstract/abstract-select/abstract-select.component';

@Component({
  selector: 'next-dropdown',
  templateUrl: './next-dropdown.component.html',
  styleUrl: './next-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextDropdownComponent extends AbstractSelectComponent implements OnChanges {
  @Input() autoSelectDefaultItem = false;
  @Input() autoDisplayFirst = false;
  @Input() appendTo: string = 'body';
  @Input() isIndexedList = false;
  @Input() isBinaryDefault = false;
  @Input() name: string;

  constructor(injector$: Injector) {
    super(injector$);
  }
}
