import { AbstractControl, FormArray, FormControl, FormControlStatus, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, of } from 'rxjs';
import { FormConfig, FormItem, FormOptions, FormValueChangeConfig } from '@utils/models/Form';
import { GenericObject } from '@utils/models/Types';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NextArrayHelper } from '@utils/core/next-array.helper';
import { NextBooleanHelper } from '@utils/core/next-boolean.helper';
import { NextInputValidators } from '../../validators/input.validator';
import { NextFormsConstant } from '@constants/forms.constant';
import { FilterItemMatchConfig, FilterListConfig } from '@utils/models/Array';

export class NextFormHelper {
  /**
   * Updates enable status for control
   *
   * @param control
   * @param isEnable defines enable status after operation
   * @param options control options
   *
   */
  static updateControlEnableStatus(control: FormItem | undefined, isEnable = false, options: FormOptions = {}): void {
    if (control) {
      if (options.resetValue) {
        control.reset(options.resetToValue, options);
      }
      isEnable ? control.enable(options) : control.disable(options);
    }
  }

  /**
   * Updates dirty status for control
   *
   * @param control
   * @param isDirty defines dirty status after operation
   * @param options control options
   *
   */
  static updateControlDirtyStatus(control: FormItem, isDirty = false, options?: FormOptions): void {
    isDirty ? control.markAsDirty(options) : control.markAsPristine(options);
  }

  /**
   * Updates required status for control
   *
   * @param control
   * @param isRequired defines required status after operation
   * @param options control options
   *
   */
  static updateControlRequiredValidator(control: FormItem, isRequired = false, options?: FormOptions): void {
    if (isRequired) {
      control.addValidators(NextInputValidators.requiredNoBlank);
    } else {
      control.removeValidators(NextInputValidators.requiredNoBlank);
    }
    control.updateValueAndValidity(options);
  }

  /**
   * Adds errors to control
   *
   * @param control
   * @param errorList
   *
   */
  static addErrorsToControl(control: FormControl, errorList: string[] = []): void {
    let errors = null;

    if (errorList.length) {
      errors = errorList.reduce((acc: GenericObject, item) => {
        acc[item] = true;

        return acc;
      }, {});

      control.markAsDirty();
    }

    control.setErrors(errors);
  }

  /**
   * Creates form group data model from list of props and entity
   *
   * @param groupProps
   * @param entityValue
   * @param formConfig
   * @returns GenericObject
   *
   */
  static buildGroupValuesWithConfig(
    groupProps: string[],
    entityValue: GenericObject = {},
    formConfig: FormConfig = {}
  ): GenericObject {
    return groupProps
      .filter(item => !NextObjectHelper.getPropertyFromObject(formConfig, [item, FormConfigProps.IS_EXCLUDED], false))
      .reduce((acc: GenericObject, item) => {
        const itemConfig: FormConfig = NextObjectHelper.getPropertyFromObject(formConfig, [item], {}) as FormConfig;
        let validators = [];

        let value = NextValueHelper.defaultValue(
          itemConfig[FormConfigProps.FIXED_START_VALUE],
          NextObjectHelper.getPropertyFromObject(entityValue, [item])
        );

        if (itemConfig[FormConfigProps.IS_LIST]) {
          value = NextArrayHelper.buildArrayFromValue(value);
        }

        if (itemConfig[FormConfigProps.IS_BOOLEAN]) {
          value = NextBooleanHelper.parseBooleanString(value);
        }

        if (itemConfig[FormConfigProps.ENTITY_PATH]) {
          value = NextObjectHelper.getPropertyFromObject(
            entityValue,
            itemConfig[FormConfigProps.ENTITY_PATH] as string[]
          );
        }

        if (itemConfig[FormConfigProps.DEFAULT_VALUE]) {
          value = NextValueHelper.defaultValue(value, itemConfig[FormConfigProps.DEFAULT_VALUE]);
        }

        if (itemConfig[FormConfigProps.IS_REQUIRED]) {
          validators.push(NextInputValidators.requiredNoBlank);
        }

        if (itemConfig[FormConfigProps.VALIDATORS]) {
          // @ts-expect-error Fromm Common
          validators = validators.concat(itemConfig[FormConfigProps.VALIDATORS]);
        }

        const valObj = { value, disabled: !!itemConfig[FormConfigProps.IS_DISABLED] };

        acc[item] = [valObj, validators];
        return acc;
      }, {});
  }

  /**
   * Creates form data model group without validators from list of props and entity
   *
   * @param groupProps
   * @param entityValue
   * @param formConfig
   * @returns GenericObject
   *
   */
  static buildGroupValues(
    groupProps: string[],
    entityValue: GenericObject = {},
    formConfig: FormConfig = {}
  ): GenericObject {
    // TODO Needs to be refactor to avoid construction with validators, instead of removing it in this function
    const valuesWithConfig = NextFormHelper.buildGroupValuesWithConfig(groupProps, entityValue, formConfig);
    return Object.keys(valuesWithConfig).reduce((acc: GenericObject, item) => {
      acc[item] = valuesWithConfig[item][0];
      return acc;
    }, {});
  }

  /**
   * Pipes form value change observable with settings
   *
   * @param control
   * @param formValueChangeConfig
   * @returns Observable
   *
   */
  static createValueChangeObs(control: FormItem, formValueChangeConfig: FormValueChangeConfig = {}): Observable<any> {
    const debounceTimeMS = NextValueHelper.defaultValue(
      formValueChangeConfig.debounceTimeMS || NextFormsConstant.formValueChangesDebounceMS
    );

    const valueChange$ = [control.valueChanges];

    if (formValueChangeConfig.emitInitialValue) {
      valueChange$.push(of(control.value));
    }

    return merge(...valueChange$).pipe(
      debounceTime(debounceTimeMS),
      filter(
        () =>
          (formValueChangeConfig.emitInvalid || control.errors === null) &&
          (!formValueChangeConfig.filterDisabled || control.enabled)
      )
    );
  }

  /**
   * Deletes item from form formArray
   *
   * @param formArray
   * @param index
   *
   */
  static deleteFromArray(formArray: FormArray, index: number): void {
    formArray.removeAt(index);
    formArray.markAsDirty();
  }

  /**
   * Resets form array with list of values
   *
   * @param formArray
   * @param values list of new values
   *
   */
  static resetFormArray(formArray: FormArray, values: any[] = []): void {
    formArray.clear();
    values.forEach(item => {
      formArray.push(item);
    });
  }

  static moveArrayItem(arrayControl: FormArray, index: number, shift: number) {
    let newIndex = index + shift;
    const maxIndex = arrayControl.length - 1;

    if (newIndex < 0) {
      newIndex = 0;
    }

    if (newIndex > maxIndex) {
      newIndex = maxIndex;
    }

    if (newIndex !== index) {
      const extraTmp = arrayControl.at(index);
      arrayControl.removeAt(index);

      arrayControl.insert(newIndex, extraTmp);
      arrayControl.markAsDirty();
    }
  }

  /**
   * Indicates if form has any value
   *
   * @param formValue
   *
   */
  static isFormEmpty(formValue: GenericObject | undefined): boolean {
    if (!formValue) {
      return true;
    }
    const hasFilledValue = Object.values(formValue).some(item => !NextValueHelper.isValueEmpty(item));

    return !hasFilledValue;
  }

  static createStatusChangeObs(control: FormItem): Observable<FormControlStatus> {
    return merge(of(control.status), control.statusChanges);
  }

  static createDisabledChangeObs(control: FormItem): Observable<boolean> {
    const status$ = NextFormHelper.createStatusChangeObs(control);

    return status$.pipe(
      map(() => control.disabled),
      distinctUntilChanged()
    );
  }

  static createIsFormValidChangeObs(control: FormItem): Observable<boolean> {
    const status$ = NextFormHelper.createStatusChangeObs(control);

    return status$.pipe(
      map(() => control.enabled && control.valid),
      distinctUntilChanged()
    );
  }

  static filterControlListByValue(
    list: FormItem[],
    matchConfigList?: FilterItemMatchConfig[],
    filterListConfig?: FilterListConfig
  ): FormItem[] {
    const getValueCb = (item: any) => item.getRawValue();

    const formFilterConfig = Object.assign(
      {
        getValueCb
      },
      filterListConfig
    );

    return NextArrayHelper.filterListByConfig(list, matchConfigList, formFilterConfig);
  }

  static getStateFromForm(group: AbstractControl): GenericObject {
    const formValue = group.getRawValue();
    let val;

    if (NextObjectHelper.isObject(formValue)) {
      return Object.entries(formValue).reduce((acc: GenericObject, [key]) => {
        const itemGroup = group.get(key);
        // @ts-expect-error Fromm Common
        val = NextFormHelper.getStateFromForm(itemGroup);
        acc[key] = val;
        return acc;
      }, {});
    } else if (Array.isArray(formValue)) {
      const array = group as FormArray;
      val = formValue.map((item, index) => NextFormHelper.getStateFromForm(array.at(index)));
    } else {
      val = { value: formValue, disabled: group.disabled };
    }

    return val;
  }

  static resetGroupForIds(currentGroup: FormGroup, updatedGroup: FormGroup, formIds: string[] | string): void {
    const idList = NextArrayHelper.buildArrayFromValue(formIds);
    idList.forEach(itemId => {
      // @ts-expect-error From Common
      const updatedState = NextFormHelper.getStateFromForm(updatedGroup.get(itemId));
      // @ts-expect-error From Common
      currentGroup.get(itemId).reset(updatedState);
    });
  }

  static createMultipartFormData(formValues: GenericObject = {}, files: GenericObject<File> = {}): FormData {
    const formData = new FormData();

    Object.entries(formValues).forEach(([key, val]) => {
      formData.append(key, JSON.stringify(val));
    });

    Object.entries(files).forEach(([key, valBlob]) => {
      const fileName = NextObjectHelper.getPropertyFromObject(valBlob, 'name');
      if (valBlob) {
        formData.append(key, valBlob, fileName);
      }
    });

    return formData;
  }

  static isControlRequired(control: FormItem): boolean {
    return [NextInputValidators.requiredNoBlank, Validators.required].some(validator =>
      control.hasValidator(validator)
    );
  }

  static markFormAsDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key)?.markAsDirty();
    });
  }
}
