<p-dropdown
  [formControl]="control"
  [readonly]="isLoading"
  [filter]="filter"
  [showClear]="showClear"
  [options]="optionList"
  [placeholder]="placeholder | translate"
  [styleClass]="'form__input'"
  (onChange)="handleChange($event)"
  [appendTo]="appendTo"
></p-dropdown>
