import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'next-textarea',
  templateUrl: './next-textarea.component.html',
  styleUrl: './next-textarea.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextTextareaComponent {
  @Input() control: FormControl;
  @Input() maxLength: number;
  @Input() placeholder: string;
}
