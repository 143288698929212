import { ColorType } from '../enums/color.enum';

export class BadgeConstant {
  static readonly classByColor: any | any[] = {
    [ColorType.NEUTRAL]: 'next-badge--neutral',
    [ColorType.CAUTION]: 'next-badge--caution',
    [ColorType.SUCCESS]: 'next-badge--success',
    [ColorType.DANGER]: 'next-badge--danger',
    [ColorType.BLUE]: 'next-badge--blue',
    [ColorType.PURPLE]: 'next-badge--purple',
    [ColorType.YELLOW]: 'next-badge--yellow'
  };
}
