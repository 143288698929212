import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitButtonComponent } from '@components/atoms/buttons/components/split-button/split-button.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { VisualModule } from '@components/atoms/visual/visual.module';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { FormInputsModule } from '../form-inputs/form-inputs.module';

const exportedComponents = [ButtonComponent, SplitButtonComponent, LinkButtonComponent, FilterButtonComponent];

@NgModule({
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
  imports: [CommonModule, ButtonModule, SplitButtonModule, TranslateModule, VisualModule, FormInputsModule]
})
export class ButtonsModule {}
