import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { NextPickListTestIds } from './enums/next-pick-list.enum';

@Component({
  selector: 'next-pick-list',
  templateUrl: './next-pick-list.component.html',
  styleUrl: './next-pick-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextPickListComponent {
  @Input() distributorsList: any;
  @Input() showSourceControls: boolean = false;
  @Input() showTargetControls: boolean = false;
  @Input() sourceHeader?: string;
  @Input() targetHeader?: string;
  @Input() sourceFilterPlaceholder?: string;
  @Input() targetFilterPlaceholder?: string;
  @Input() listSelectedDistributors: any;
  @Input() emptyTargetSection: string = 'USER.FIELDS.NO_DATA';
  @Input() showTargetFilter:boolean = false;

  @Output() moveDistributorToSource: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveDistributorToTarget: EventEmitter<any> = new EventEmitter<any>();

  protected readonly NextIcon = NextIcon;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly IconColor = IconColor;
  protected readonly NextPickListTestIds = NextPickListTestIds;
}
