import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { MeEndpointsConstant } from './me.endpoints.constant';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { MeSession } from '../types/me.type';
import { ApiResponse } from '../types/api.type';
import { ApiProps } from '../enums/api.enum';
import { UserManagerEndpoints } from '@pages/general-maintenance/pages/user/constants/user-manager.endpoints.constant';
import { UserManagerConstant } from '@pages/general-maintenance/pages/user/constants/user-manager.constant';
import { UserManager } from '@pages/general-maintenance/pages/user/types/user.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NavigationConfigProps } from 'app/shared/enums/navigation.enum';
import { ToastMsgService } from '@services/toast-msg.service';
import { MsgProps } from 'app/shared/enums/backend.enum';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private _meSession: MeSession;

  constructor(
    private _backendService: BackendService,
    private _toastService: ToastMsgService
  ) {}

  get(): Observable<MeSession> {
    return this._backendService.get(MeEndpointsConstant.config.MAIN).pipe(
      switchMap((result: ApiResponse<MeSession>) => {
        return of(result[ApiProps.RESULT]);
      })
    );
  }

  setMeSession(meSession: MeSession) {
    this._meSession = { ...meSession };
  }

  getMeSession() {
    return this._meSession;
  }

  getAndSetMeSession() {
    return this.get().pipe(tap((meSession: MeSession) => this.setMeSession(meSession)));
  }

  getUsers() {
    return this._backendService.get(UserManagerEndpoints.config.LIST).pipe(
      map((response: ApiResponse<any>) => {
        const users = NextObjectHelper.getPropertyFromObject(response, ['result', 'users'], []);
        if (users.length) {
          return users.map((user: UserManager) => {
            return {
              ...user,
              userName: user.givenName + ' ' + user.familyName,
              statusColorType: UserManagerConstant.colorStatusConfig[user.status],
              statusLabelKey: UserManagerConstant.statusConfig[user.status]
            };
          });
        }
      })
    );
  }

  updateUser(id: number, body: any) {
    return this._backendService
      .put(UserManagerEndpoints.config.EDIT_USER, body, {
        [NavigationConfigProps.URL_PARAMS]: { id }
      })
      .pipe(
        map((response: ApiResponse<any>) => {
          const user = NextObjectHelper.getPropertyFromObject(response, ['result', 'user'], []);
          if (user) {
            this.handleSaveSuccess();
            return { ...user };
          }
        })
      );
  }

  protected handleSaveSuccess() {
    this._showSuccessMsg();
  }

  private _showSuccessMsg() {
    this._toastService.success('API.SAVE_SUCCESS', { [MsgProps.ENTITY]: [MsgProps.SECTION_USER] });
  }
}
