import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationExtras,
  ParamMap,
  Router
} from '@angular/router';
import { filter, Observable } from 'rxjs';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NextBrowserHelper } from '@utils/core/next-browser.helper';

@Injectable({
  providedIn: 'root'
})
export class CommonRoutingService {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  routerItemToUrl(routerItems: string[], extras?: NavigationExtras): string {
    const urlTree = this._router.createUrlTree(routerItems, extras);
    return this._router.serializeUrl(urlTree);
  }

  navigateByUrlWrapper(url: string, newTab = false) {
    if (newTab) {
      NextBrowserHelper.openNewTab(url);
      return Promise.resolve(true);
    } else {
      return this._router.navigateByUrl(url);
    }
  }

  getQueryParamFromSnapshot(paramKey: string, snapshot: ActivatedRouteSnapshot = this._activatedRoute.snapshot): any {
    return this._getUpperCaseValFromMap(snapshot.queryParamMap, paramKey);
  }

  getQueryParamFromDeepestRoute(paramKey: string): any {
    return this.getQueryParamFromSnapshot(paramKey, this.getSnapshotFromDeepestRoute());
  }

  hasQueryParameterValue(parameterName: string, matchValue: string): boolean {
    const queryParamVal = this.getQueryParamFromDeepestRoute(parameterName);

    return `${queryParamVal}`.toUpperCase() === `${matchValue}`.toUpperCase();
  }

  getRouterEventsByType(eventTypes: any[] = [NavigationEnd]): Observable<any> {
    return this._router.events.pipe(
      filter(event => !eventTypes.length || eventTypes.some((validEvent: any) => event instanceof validEvent))
    );
  }

  getSnapshotFromDeepestRoute(
    snapshot: ActivatedRouteSnapshot = this._activatedRoute.snapshot
  ): ActivatedRouteSnapshot {
    const firstChild = snapshot.firstChild;

    return !!firstChild ? this.getSnapshotFromDeepestRoute(firstChild) : snapshot;
  }

  getDataParamFromSnapshot(param: string): any {
    const snapshot = this.getSnapshotFromDeepestRoute();

    return NextObjectHelper.getPropertyFromObject(snapshot, ['data', param]);
  }

  getParamFromSnapshot(paramKey: string, snapshot: ActivatedRouteSnapshot = this._activatedRoute.snapshot): any {
    return this._getUpperCaseValFromMap(snapshot.paramMap, paramKey);
  }

  getParamFromDeepestRoute(paramKey: string): any {
    return this.getParamFromSnapshot(paramKey, this.getSnapshotFromDeepestRoute());
  }

  private _getUpperCaseValFromMap(map: ParamMap, key: string): string {
    const val = map.get(key);

    // @ts-expect-error From Common
    return !!val ? `${val}`.toUpperCase() : val;
  }
}
