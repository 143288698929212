<a (click)="handleLinkClick($event)"
   [class.link-button--disabled]="isDisabled"
   [class.link-button--action]="isAction"
   [class.link-button--icon-left]="iconPosition === LinkButtonPosition.LEFT"
   [class.link-button--icon-center]="iconPosition === LinkButtonPosition.CENTER"
   class="link-button">
  <span *ngIf="label" class="link-button__label">{{label}}</span>
  <ng-container *ngIf="iconType">
    <next-icon [iconType]="iconType" [size]="iconSize" [color]="iconColor"></next-icon>
  </ng-container>
</a>
