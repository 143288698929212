import { GenericObject } from '@utils/models/Types';
import {
  CalendarPrimeDateFormat,
  CalendarViewType
} from '@components/atoms/form-inputs/components/next-calendar/enums/calendar.enum';

export class DateSelectionConstant {
  static readonly dateFormatByType: GenericObject = {
    [CalendarViewType.DATE]: CalendarPrimeDateFormat.DATE,
    [CalendarViewType.MONTH]: CalendarPrimeDateFormat.MONTH,
    [CalendarViewType.YEAR]: CalendarPrimeDateFormat.YEAR
  };
}
