import { NextValueHelper } from './next-value.helper';
import { NextArrayHelper } from './next-array.helper';
import _ from 'lodash';
import { GenericFunction, GenericObject } from '@utils/models/Types';

export class NextObjectHelper {
  static isEmptyObject(obj: GenericObject<any>): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  static getPropertyFromObject(
    value: GenericObject | any,
    pathKey: string | (string | any | undefined)[] = [],
    defaultValue?: any
  ): any {
    const pathKeyList = NextArrayHelper.buildArrayFromValue(pathKey);
    return pathKeyList.reduce((object, path) => {
      const propVal = (object || {})[path];
      return NextValueHelper.isValueDefined(propVal) ? propVal : defaultValue;
    }, value);
  }

  static replaceUndefinedPropWithDefault(obj: GenericObject<any>, prop: string, defaultValue: any): GenericObject<any> {
    const objVal = { ...obj };
    if (!objVal[prop]) {
      objVal[prop] = defaultValue;
    }

    return objVal;
  }

  static getObjectDefault(val: GenericObject<any>): GenericObject<any> {
    return NextValueHelper.defaultValue(val, {});
  }

  static cleanObject(obj: GenericObject<any>, isCleanEmpty = false): GenericObject<any> {
    const validationCb = (val: GenericFunction) =>
      isCleanEmpty ? !NextValueHelper.isValueEmpty(val) : NextValueHelper.isValueDefined(val);
    return Object.keys(obj).reduce((acc: any, key) => {
      const val = obj[key];
      if (validationCb(val)) {
        acc[key] = val;
      }
      return acc;
    }, {});
  }

  static buildMapFromObject(obj: GenericObject<any>, itemCb?: GenericFunction): Map<any, any> {
    return new Map(NextObjectHelper.buildEntryListFromObj(obj, itemCb));
  }

  static pickFromObject = (obj: any, keys: string[], isExclude = false) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => {
        const isProp = keys.includes(key);

        return isExclude ? !isProp : isProp;
      })
    );

  static deepClone(objectValue: any): any {
    return _.cloneDeep(objectValue);
  }

  static isEqual(object1: GenericObject, object2: GenericObject) {
    return _.isEqual(object1, object2);
  }

  static deepMerge(value: GenericObject, source: GenericObject, isObjectCloned = false): GenericObject {
    const baseObj = isObjectCloned ? NextObjectHelper.deepClone(value) : value;

    return _.merge(baseObj, source);
  }

  static buildEntryListFromObj(obj: GenericObject<any>, itemCb?: GenericFunction): any[] {
    return Object.entries(obj).map(([key, val]) => [key, itemCb ? itemCb(val) : val]);
  }

  static buildKeyListFromMap(map: Map<any, any> = new Map()): any[] {
    return [...map.keys()];
  }

  static getDeepestChild(object: GenericObject<any>, childProperty: string): any {
    const firstChild = object[childProperty];

    return !!firstChild ? NextObjectHelper.getDeepestChild(firstChild, childProperty) : object;
  }

  static isObject(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  static isValueInObject(value: any, obj: GenericObject = {}): boolean {
    return Object.values(obj).includes(value);
  }

  static addValueForMultipleKeys(objVal: GenericObject, value: any, keys: string[]) {
    keys.forEach(itemKey => {
      objVal[itemKey] = value;
    });
  }

  static removeValueForMultipleKeys(objVal: GenericObject, keys: string[]) {
    keys.forEach(itemKey => {
      delete objVal[itemKey];
    });
  }
}
