import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'column-pair',
  templateUrl: './column-pair.component.html',
  styleUrl: './column-pair.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnPairComponent {
  @Input() isVertical = false;
}
