import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateUnits } from '../../../../../core/enums/date.enum';
import { CalendarViewType } from '@components/atoms/form-inputs/components/next-calendar/enums/calendar.enum';
import { DateFromToControls } from '@components/atoms/form-inputs/components/date-from-to/date-from-to.enum';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NextDateHelper } from '@utils/core/next-date.helper';
import { GenericObject } from '@utils/models/Types';
import { DateFromToConstant } from '@components/atoms/form-inputs/components/date-from-to/date-from-to.constant';
import { DateConstant } from '../../../../../core/constants/date.constant';

@Component({
  selector: 'date-from-to',
  templateUrl: './date-from-to.component.html',
  styleUrl: './date-from-to.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFromToComponent implements OnInit {
  @Input() fromControl: FormControl;
  @Input() toControl: FormControl;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() maxRangeValue: number;
  @Input() maxRangeUnit: DateUnits = DateUnits.DAY;
  @Input() defaultFromDate: any;
  @Input() defaultToDate: any;
  @Input() viewMode: CalendarViewType = CalendarViewType.DATE;
  @Input() styleClass?:string;
  @Input() appendTo: string = 'body';

  controlById: GenericObject<FormControl>;
  maxRangeDate: Date;
  protected readonly DateFromToControls = DateFromToControls;

  ngOnInit(): void {
    this.controlById = {
      [DateFromToControls.FROM]: this.fromControl,
      [DateFromToControls.TO]: this.toControl
    };
    this._validateRange();
  }

  handleFromChange(date: Date): void {
    this.handleValueChange(date, DateFromToControls.FROM);
    this._validateRange();
    this.toControl.reset();
  }

  handleValueChange(date: Date, id: DateFromToControls): void {
    const control = this.controlById[id];

    this._adjustDate(date, control, id);
  }

  private _adjustDate(date: Date, control: FormControl, id: DateFromToControls): void {
    let dateString = null;
    if (!!date) {
      const isEndOf = DateFromToConstant.isEndOfByControlId[id];
      const unitOfTime = DateConstant.unitOfTimeByCalendarType[this.viewMode];
      dateString = NextDateHelper.buildAdjustedUTCDateISoString(date, isEndOf, unitOfTime);
    }
    
    control.setValue(dateString);
  }

  private _validateRange(): void {
    const fromValue = this.fromControl.value;

    if (NextValueHelper.isValueDefined(this.maxRangeValue) && !!fromValue) {
      const maxRangeDate = NextDateHelper.addUnitToDate(this.maxRangeValue, this.maxRangeUnit, fromValue);
      this.maxRangeDate = !!this.maxDate && maxRangeDate > this.maxDate ? this.maxDate : maxRangeDate;
    }
  }
}
