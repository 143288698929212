import { Pipe, PipeTransform } from '@angular/core';
import { FormItem } from '@utils/models/Form';

@Pipe({
  name: 'deepControl'
})
export class DeepControlPipe implements PipeTransform {
  transform(formGroup: FormItem, props: (string | number)[]): any {
    return formGroup.get(props);
  }
}
