<p-pickList class="next-picklist"
    [source]="distributorsList"
    [target]="listSelectedDistributors"
    [sourceHeader]="sourceHeader"
    [responsive]="true" 
    filterBy="name" 
    [sourceFilterPlaceholder]="sourceFilterPlaceholder" 
    [targetFilterPlaceholder]="targetFilterPlaceholder"
    [showSourceControls]="showSourceControls"
    [showTargetControls]="showTargetControls"
    (onSourceSelect)="moveDistributorToTarget.emit($event.items)"
    (onTargetSelect)="moveDistributorToSource.emit($event.items)">
    <ng-template let-distributor pTemplate="item">
        <div class="flex-wrap next-picklist__item" [testIdDataAttr]="NextPickListTestIds.ITEM">
            <span class="font-bold">{{ distributor.name }}</span>
            <span class="next-picklist__icon">
                <next-icon [iconType]="NextIcon.ARROW_RIGHT" [size]="IconSizeModifier.SMALL" [color]="IconColor.BRAND_PRIMARY" [testIdDataAttr]="NextPickListTestIds.BUTTON_ITEM"></next-icon>
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="sourceHeader">
        <div class="p-picklist-source-header">
            <div class="p-picklist-title">
                {{sourceHeader}}
            </div>
            <div>{{'BUTTONS.ADD' | translate}}</div>
        </div>
    </ng-template>
    <ng-template pTemplate="targetFilter">
        <div class="p-picklist-target-header">
            <div>{{'BUTTONS.REMOVE' | translate}}</div>
            <div class="p-picklist-title">
                {{targetHeader}}
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="emptymessagetarget">
        <span>{{ emptyTargetSection | translate }}</span>
    </ng-template>
</p-pickList>