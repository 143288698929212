import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchComponent } from '@components/atoms/form-inputs/components/input-switch/input-switch.component';
import { InputContainerComponent } from '@components/atoms/form-inputs/components/input-container/input-container.component';
import { NextInputComponent } from '@components/atoms/form-inputs/components/next-input/next-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { NextToggleButtonComponent } from './components/next-toggle-button/next-toggle-button.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { NextDropdownComponent } from './components/next-dropdown/next-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { NextMultiSelectComponent } from './components/next-multi-select/next-multi-select.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AbstractSelectComponent } from './components/abstract/abstract-select/abstract-select.component';
import { NextBadgeComponent } from './components/next-badge/next-badge.component';
import { BadgeModule } from 'primeng/badge';
import { NextTagComponent } from './components/next-tag/next-tag.component';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { NextCalendarComponent } from './components/next-calendar/next-calendar.component';
import { AbstractCalendarComponent } from './components/abstract/abstract-calendar/abstract-calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { PipesModule } from '@pipes/pipes.module';
import { NextRadioComponent } from './components/next-radio/next-radio.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TestIdDataAttrDirective } from '../../../core/directives/test-id-data-attr.directive';
import { NextInputNumberComponent } from './components/next-input-number/next-input-number.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ValidationMessagesComponent } from '@components/atoms/form-inputs/components/validation-messages/validation-messages.component';
import { AppTranslationModule } from '../../../core/translation/app-translation.module';
import { NextPickListComponent } from './components/next-pick-list/next-pick-list.component';
import { PickListModule } from 'primeng/picklist';
import { NextTextareaComponent } from './components/next-textarea/next-textarea.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DateFromToComponent } from '@components/atoms/form-inputs/components/date-from-to/date-from-to.component';
import { DateIsoStringPipe } from '@pipes/common/date-iso-string.pipe';
import { VisualModule } from '../visual/visual.module';
const exportedComponents = [
  InputSwitchComponent,
  NextInputComponent,
  InputContainerComponent,
  NextToggleButtonComponent,
  NextDropdownComponent,
  NextMultiSelectComponent,
  NextBadgeComponent,
  NextTagComponent,
  NextMultiSelectComponent,
  NextCalendarComponent,
  NextRadioComponent,
  NextInputNumberComponent,
  ValidationMessagesComponent,
  NextPickListComponent,
  NextTextareaComponent,
  DateFromToComponent
];

@NgModule({
  declarations: [...exportedComponents, AbstractSelectComponent, AbstractCalendarComponent],
  exports: [...exportedComponents],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    InputSwitchModule,
    TranslateModule.forChild({ extend: true }),
    ToggleButtonModule,
    DropdownModule,
    MultiSelectModule,
    TooltipModule,
    CalendarModule,
    PipesModule,
    RadioButtonModule,
    TestIdDataAttrDirective,
    BadgeModule,
    TagModule,
    TooltipModule,
    InputNumberModule,
    AppTranslationModule,
    PickListModule,
    InputTextareaModule,
    DateIsoStringPipe,
    VisualModule
  ]
})
export class FormInputsModule {}
