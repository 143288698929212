export enum DateUnits {
  MONTH = 'month',
  YEAR = 'year',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
  MILISECOND = 'milisecond'
}

export enum DateFormat {
  DATE = 'dd/MM/yyyy',
  DATE_TIME = 'dd/MM/yyyy HH:mm:ss',
  MONTH_YEAR = 'MM/yyyy',
  TIME = 'HH:mm'
}

export enum TimeCompareReference {
  AFTER = 'after',
  BEFORE = 'before',
  SAME = 'same'
}
