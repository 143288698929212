import { Pipe, PipeTransform } from '@angular/core';
import { NextValueHelper } from '@utils/core/next-value.helper';

@Pipe({
  name: 'defaultValue',
  standalone: true
})
export class DefaultValuePipe implements PipeTransform {
  transform(value: any, defaultValue: any): any {
    return NextValueHelper.defaultValue(value, defaultValue);
  }
}
