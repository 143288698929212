import { inject, Pipe, PipeTransform } from '@angular/core';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'displayList',
  standalone: true
})
export class DisplayListPipe implements PipeTransform {
  private _translate: TranslateService;

  constructor() {
    this._translate = inject(TranslateService);
  }

  transform(
    list: any[],
    textProp?: string,
    hasTranslation = false,
    translationPrefix?: string,
    customSeparator = ', '
  ): any {
    if (NextValueHelper.isValueDefined(list)) {
      const filteredList = list.filter(item => !!item).map(item => (!!textProp ? item[textProp] : item));
      const listVal = hasTranslation ? this._translateList(filteredList, translationPrefix) : filteredList;

      return listVal.join(`${customSeparator}`).trim();
    }
  }

  private _translateList(list: any[], translationPrefix?: string): any[] {
    const prefix = !!translationPrefix ? `${translationPrefix}.` : '';

    return list.map(item => this._translate.instant(`${prefix}${item}`));
  }
}
