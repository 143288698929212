import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeepControlPipe } from '@pipes/common/deep-control.pipe';
import { DeepControlValuePipe } from '@pipes/common/deep-control-value.pipe';
import { GetValuePipe } from './get-value.pipe';
import { IsDisabledFromControlStatusPipe } from '@pipes/common/is-enabled-from-control-status.pipe';
import { DisabledChangePipe } from '@pipes/common/disabled-change.pipe';
import { SafeGuardObjectPropertyPipe } from '@pipes/common/safe-guard-object-property.pipe';
import { DateObjectPipe } from '@pipes/common/date-object.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { GetIndexOfPipe } from './get-indexOf.pipe.spec';

const exported = [
  DeepControlPipe,
  DeepControlValuePipe,
  GetValuePipe,
  DisabledChangePipe,
  IsDisabledFromControlStatusPipe,
  SafeGuardObjectPropertyPipe,
  DateObjectPipe,
  GetIndexOfPipe
];

@NgModule({
  declarations: [...exported],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [...exported],
  providers: [DeepControlPipe]
})
export class PipesModule {}
