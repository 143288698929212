import { PatternConstant } from '../../core/constants/pattern.constant';
import { GenericObject } from '@utils/models/Types';

export class NextFormsConstant {
  static readonly formValueChangesDebounceMS = 100;
  static readonly formTextInputValueChangesDebounceMS = 600;
  static readonly patternErrorKeyMapping: GenericObject = {
    [PatternConstant.numericPattern]: 'FORM.PATTERN.NUMBERS',
    [PatternConstant.alphaNumericPattern]: 'FORM.PATTERN.ALPHA_NUM',
    [PatternConstant.alphaNumericPatternWithSpaces]: 'FORM.PATTERN.ALPHA_NUM_SPACES',
    [PatternConstant.alphaNumericPatternWithSpecialChars]: 'FORM.PATTERN.ALPHA_NUM_WITH_CHARS',
    [PatternConstant.phonePattern]: 'FORM.PATTERN.PHONE'
  };
}
