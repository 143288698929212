import { inject, Pipe, PipeTransform } from '@angular/core';
import { DisplayListPipe } from '@pipes/display-list.pipe';

@Pipe({
  name: 'appendText',
  standalone: true
})
export class AppendTextPipe implements PipeTransform {
  private _displayListPipe: DisplayListPipe;

  constructor() {
    this._displayListPipe = inject(DisplayListPipe);
  }

  transform(list: string[] | number[] | boolean[] | any[], customSeparator = ' - '): any {
    return this._displayListPipe.transform(list, undefined, undefined, undefined, customSeparator);
  }
}
