import { Injectable } from '@angular/core';
import { EndPointItem, HttpOptions, HttpReqConfig } from '@wwtfTypes/backend.type';
import { iif, mergeMap, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { GenericObject } from '@utils/models/Types';
import { ToastMsgService } from '@services/toast-msg.service';
import { HttpConfigProps } from '../enums/backend.enum';
import { BackendConstant } from '@constants/backend.contant';
import { NextObjectHelper } from '@utils/core/next-object.helper';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(
    private _http: HttpClient,
    private _toast: ToastMsgService
  ) {}

  get(endpointItem: EndPointItem, httpOptions: HttpOptions = {}): Observable<any> {
    if (endpointItem.mock) {
      return of(endpointItem.mock);
    } else {
      // TODO Get call
      const url = this.buildEndpointUrlParams(endpointItem, httpOptions?.urlParams);

      return (
        this._http
          // @ts-expect-error TODO Fix options
          .get(url, httpOptions)
          .pipe(
            mergeMap(res =>
              iif(
                () => !!httpOptions.resultWithinProp,
                of(NextObjectHelper.getPropertyFromObject(res, httpOptions.resultWithinProp!)),
                of(res)
              )
            )
          )
      );
    }
  }

  put(endpointItem: EndPointItem, body?: GenericObject, config: HttpReqConfig = {}) {
    let req$;
    if (endpointItem.mock) {
      req$ = of(endpointItem.mock);
    } else {
      const url = this.buildEndpointUrlParams(endpointItem, config?.urlParams);
      // @ts-expect-error TODO Fix options
      req$ = this._http.put<any>(url, body, config);
    }
    return req$;
  }

  post(endpointItem: EndPointItem, body?: GenericObject, config: HttpReqConfig = {}) {
    let req$;
    if (endpointItem.mock) {
      req$ = of(endpointItem.mock);
    } else {
      const url = this.buildEndpointUrlParams(endpointItem, config?.urlParams);
      // @ts-expect-error TODO Fix options
      req$ = this._http.post<any>(url, body, config);
    }
    return this._pipeHttpRequest(req$, config);
  }

  delete(endpointItem: EndPointItem, body?: GenericObject, config: HttpReqConfig = {}) {
    let req$;
    if (endpointItem.mock) {
      req$ = of(endpointItem.mock);
    } else {
      const url = this.buildEndpointUrlParams(endpointItem, config?.urlParams);
      // @ts-expect-error TODO Fix options
      req$ = this._http.delete<any>(url, body, config);
    }
    return req$;
  }
  
  buildApiUrl() {
    return `${environment.BASE_URL_API}/${BackendConstant.apiParam}/${environment.API_VERSION}/`;
  }

  buildEndpointUrl(endpoint: EndPointItem, param?: string | number): string {
    const url = this._buildEndpointUrl(endpoint);

    return param ? `${url}/${param}` : url;
  }

  buildEndpointUrlParams(endpoint: EndPointItem, params: GenericObject = {}): string {
    const url = this._buildEndpointUrl(endpoint);
    return Object.keys(params).reduce((acc, key) => {
      const itemVal = params[key];
      const paramValue = itemVal || '';
      let paramString = `{{${key}}}`;
      if (!itemVal) {
        paramString = `/${paramString}`;
      }
      return acc.replace(paramString, `${paramValue}`.trim());
    }, url);
  }

  private _buildEndpointUrl(endpoint: EndPointItem): string {
    const { url } = endpoint;
    const apiBase = this.buildApiUrl();
    return `${apiBase}${url}`;
  }

  private _pipeHttpRequest(req$: Observable<any>, config: HttpReqConfig) {
    return req$.pipe(
      tap(() => {
        if (config[HttpConfigProps.SUCCESS_MSG]) {
          this._toast.success(config[HttpConfigProps.SUCCESS_MSG]);
        }
      })
    );
  }
}
