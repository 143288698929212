import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { map, merge, Observable, of } from 'rxjs';
import { FormItem } from '@utils/models/Form';
import { NextFormHelper } from '@utils/core/next-form.helper';

@Component({
  selector: 'input-container',
  templateUrl: './input-container.component.html',
  styleUrl: './input-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputContainerComponent implements OnInit {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() control: FormItem;
  @Input() isFourEye: boolean = false;
  @Input() isSingleRow: boolean = false;
  @Input() hideRequiredSymbol: boolean = false;
  @Input() showValidationMessages = true;
  @Input() showValidationMessagesOnDisabled = false;

  showRequiredSign$: Observable<boolean>;

  ngOnInit() {
    this.defineIsControlRequired();
  }

  private defineIsControlRequired(): void {
    const instant$ = of(false);
    this.showRequiredSign$ = instant$;

    if (this.control) {
      const statusChanges$ = this.control.statusChanges;
      this.showRequiredSign$ = merge(instant$, statusChanges$).pipe(map(() => this._isRequiredSignVisible()));
    }
  }

  private _isRequiredSignVisible(): boolean {
    return this.control.enabled && NextFormHelper.isControlRequired(this.control);
  }
}
