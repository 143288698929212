import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextIconComponent } from './components/next-icon/next-icon.component';
import { IconClassPipe } from '@components/atoms/visual/pipes/icon-class.pipe';
import { DataCardComponent } from './components/data-card/data-card.component';
import { DataTitleComponent } from './components/data-title/data-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes/pipes.module';
import { AppendTextPipe } from '@pipes/append-text.pipe';
import { DisplayListPipe } from '@pipes/display-list.pipe';
import { NextMessageComponent } from './components/next-message/next-message.component';
import { MessagesModule } from 'primeng/messages';
import { TabTitleComponent } from './components/tab-title/tab-title.component';
import { DefaultValuePipe } from '@pipes/common/default-value.pipe';
import { PipeListFormatPipe } from '@pipes/common/pipe-list-format.pipe';
import { DynamicPipe } from '@pipes/common/dynamic.pipe';

const exportedElements = [
  NextIconComponent,
  DataCardComponent,
  DataTitleComponent,
  IconClassPipe,
  NextMessageComponent,
  TabTitleComponent
];

@NgModule({
  declarations: [...exportedElements],
  exports: [...exportedElements],
  imports: [CommonModule, TranslateModule, PipesModule, AppendTextPipe, MessagesModule, DefaultValuePipe],
  providers: [DisplayListPipe, PipeListFormatPipe, DynamicPipe]
})
export class VisualModule {}
