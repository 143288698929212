import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { ColorType } from 'app/shared/enums/color.enum';

@Component({
  selector: 'filter-button',
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterButtonComponent {
  @Input() hasExtraFilter: boolean = false;

  @Output() clickEvt = new EventEmitter<Event>();

  protected readonly ColorType = ColorType;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly NextIcon = NextIcon;

  handleLinkClick(event: Event) {
    this.clickEvt.emit(event);
  }
}
