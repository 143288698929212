import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NextObservableHelper } from '@utils/core/next-observable.helper';

@Component({
  selector: 'generic-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericBaseComponent implements OnDestroy {
  subs: Subscription[] = [];

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe(): void {
    NextObservableHelper.unsubscribeSubscriptions(this.subs);
  }
}
