export enum NextRouteProps {
  ID = 'id',
  PATH = 'path',
  PATH_PARAM = 'pathParam',
  LABEL = 'label',
  ICON = 'icon',
  PARENT = 'parent',
  PERMISSION = 'permission',
  ROUTE_ITEM_CONFIG = 'routeConfig',
  FORM_SECTION_CONFIG = 'formSectionConfig',
  KEEP_QUERY_PARAMS = 'keepQueryParams',
  HIDE_BREADCRUMB_ITEM = 'hideBreadcrumbItem',
  DISABLED_BREADCRUMB_ITEM = 'disabledBreadcrumbItem',
  FROM_URL_PARAM_PATH = 'fromUrlParamPath'
}

export enum RouteParams {
  ID = 'id',
  STEP_FORM = 'stepForm',
  TASK_ID = 'taskId',
  COUNTRY_ID = 'countryId'
}
