import { ChangeDetectionStrategy, Component, Injector, OnChanges } from '@angular/core';
import { AbstractSelectComponent } from '@components/atoms/form-inputs/components/abstract/abstract-select/abstract-select.component';

@Component({
  selector: 'next-multi-select',
  templateUrl: './next-multi-select.component.html',
  styleUrl: './next-multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextMultiSelectComponent extends AbstractSelectComponent implements OnChanges {
  constructor(injector$: Injector) {
    super(injector$);
  }
}
