import { ButtonSeverity, ButtonType } from '@components/atoms/buttons/components/button/enums/buttons.enum';

export class ButtonConstants {
  static readonly severityByTpe: any = {
    [ButtonType.PRIMARY]: ButtonSeverity.PRIMARY,
    [ButtonType.SECONDARY]: ButtonSeverity.PRIMARY,
    [ButtonType.CONTRAST]: ButtonSeverity.CONTRAST
  };
  static readonly outlinedByType: any = {
    [ButtonType.SECONDARY]: true
  };
  static readonly outlinedDefault = false;
}
