import {
  DropdownConfig,
  DropdownWithIndex
} from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { NextValueHelper } from '@utils/core/next-value.helper';
import {
  DropdownConfigProps,
  DropdownProps
} from '@components/atoms/form-inputs/components/next-dropdown/enums/dropdown.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NextArrayHelper } from '@utils/core/next-array.helper';

export class DropdownHelper {
  static buildDropdownList(listArg: any[], config: DropdownConfig = {}) {
    const list = NextValueHelper.defaultValue(listArg, []);
    const disabledCb = config.disabledCb;

    const listResult = list.map((item: any) => {
      const value = this._getItemKeyValue(item, config.valueProp);
      const disabled = !!disabledCb && disabledCb(item);

      const label = this._buildDropdownLabel(value, item, config);
      const isFixed = NextObjectHelper.getPropertyFromObject(item, [config[DropdownConfigProps.IS_FIXED_PROP]], false);

      return {
        [DropdownProps.VALUE]: value,
        [DropdownProps.LABEL]: label,
        [DropdownProps.IS_DEFAULT]: isFixed || this._isDefaultItem(value, item, config),
        [DropdownProps.IS_DISABLED]: disabled,
        [DropdownProps.IS_FIXED]: isFixed,
        [DropdownProps.ICON]: NextObjectHelper.getPropertyFromObject(item, [config[DropdownConfigProps.ICON_PROP]])
      };
    });

    if (!config[DropdownConfigProps.KEEP_SORTING]) {
      NextArrayHelper.sortByConfig(listResult, [
        {
          key: DropdownProps.LABEL,
          isDescending: config[DropdownConfigProps.IS_DESCENDING_SORT]
        }
      ]);
    }

    return listResult;
  }

  static buildDropdownListWithIndex(valueList: any[] = [], config: DropdownConfig): DropdownWithIndex {
    // @ts-expect-error From Common
    const values = NextArrayHelper.indexArrayByProperty(valueList, config.valueProp);
    const list = DropdownHelper.buildDropdownList(valueList, config);

    return {
      list,
      values
    };
  }

  private static _getItemKeyValue(item: any, propertyKey?: string | (any | string)[]): any {
    return propertyKey ? NextObjectHelper.getPropertyFromObject(item, propertyKey) : item;
  }

  private static _isDefaultItem(value: any, item: any, config: DropdownConfig): boolean {
    const defaultValue = config.defaultValue;

    if (defaultValue) {
      return value === defaultValue;
    }

    return NextObjectHelper.getPropertyFromObject(item, [config.defaultProp], false);
  }

  private static _buildDropdownLabel(value: any, item: any, config: DropdownConfig) {
    const translateCb = config[DropdownConfigProps.TRANSLATE_CB];
    const labelExtraProp = config[DropdownConfigProps.LABEL_EXTRA_PROP];
    const labelMapping = config.labelMapping;
    let labelValue = DropdownHelper._getItemKeyValue(item, config.labelProp);

    if (labelMapping) {
      const valueLabelMapping = labelMapping[value];
      if (valueLabelMapping) {
        labelValue = valueLabelMapping;
      } else {
        labelValue = value;
        console.error(`No translation mapping provided for ${value}`);
      }
    }

    if (translateCb) {
      const translatePrefix = config[DropdownConfigProps.LABEL_TRANSLATION_PREFIX];
      if (translatePrefix) {
        labelValue = `${translatePrefix}.${labelValue}`;
      }

      labelValue = translateCb(labelValue);
    }

    if (labelExtraProp) {
      const labelExtraPropValue = DropdownHelper._getItemKeyValue(item, config[DropdownConfigProps.LABEL_EXTRA_PROP]);
      return `${labelValue} - ${labelExtraPropValue}`;
    }

    return `${labelValue}`;
  }
}
