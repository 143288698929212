import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastMsgService {
  private messageService: MessageService = inject(MessageService);
  private translate: TranslateService = inject(TranslateService);

  success(text: string, element?: any) {
    this.messageService.add({ severity: 'success', summary: this.translate.instant('TOAST.SUCCESS'), detail: this.translate.instant(text, element), closable: true });
  }

  warning(text: string, lifeMs?: number) {
    this.messageService.add({ severity: 'warn', summary: this.translate.instant('TOAST.WARN'), detail: this.translate.instant(text), closable: true, life: lifeMs });
  }

  error(text: string) {
    this.messageService.add({ severity: 'error', summary: this.translate.instant('TOAST.ERROR'), detail: this.translate.instant(text), life: 60000 });
  }

  info(text: string) {
    this.messageService.add({ severity: 'info', summary: this.translate.instant('TOAST.INFO'), detail: this.translate.instant(text), life: 1000, closable: false });
  }
}
