import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import {
  BadgePermissionProps,
  NextFilterType,
  TableFieldProps
} from '@components/organisms/next-table/enums/next-table.enum';
import { ColorType } from 'app/shared/enums/color.enum';
import { GenericObject } from '@utils/models/Types';
import { StatusMap } from '../../../core/types/status.type';
import { PipeConfig } from '../../../core/types/pipes.type';

export interface TableField {
  [TableFieldProps.FIELD]: string | any;
  [TableFieldProps.HEADER]?: string;
  [TableFieldProps.IS_FILTER]?: boolean;
  [TableFieldProps.IS_SORTABLE]?: boolean;
  [TableFieldProps.SORTABLE_FIELD]?: string;
  [TableFieldProps.IS_TOOLTIP]?: boolean;
  [TableFieldProps.IS_HIDDEN]?: boolean;
  [TableFieldProps.TEMPLATE_TYPE]?: TemplateType;
  [TableFieldProps.FIELD_TYPE]?: FieldType[];
  [TableFieldProps.ACTION]?: ActionField;
  [TableFieldProps.SEPARATOR]?: string;
  [TableFieldProps.ALIGN]?: AlignRow;
  [TableFieldProps.ALIGN_BUTTON]?: LinkButtonPosition;
  [TableFieldProps.FILTER_TYPE]?: NextFilterType;
  [TableFieldProps.WIDTH]?: ColumnSize;
  [TableFieldProps.STATUS_MAP]?: GenericObject<StatusMap>;
  [TableFieldProps.TOOLTIP]?: string;
  [TableFieldProps.PIPE_LIST]?: PipeConfig[] | PipeConfig[][];
  [TableFieldProps.FILTER_FIELD]?: string;
}

export enum FieldType {
  DATE = 'DATE',
  ARRAY = 'ARRAY'
}

export enum TemplateType {
  MENU_ACTION = 'menuAction',
  ACTION = 'parameters',
  STATUS = 'status',
  PERMISSION = 'permission',
  COLUMN_TEMPLATE = 'columnTemplate',
  TASK = 'task'
}

export interface ActionField {
  icon?: string;
  tooltip?: string;
  disabled?: (..._: any) => boolean;
}

export enum AlignRow {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export interface PermissionControlConfig {
  [BadgePermissionProps.CONTROL_KEY]: string;
  [BadgePermissionProps.LABEL_KEY]: string;
  [BadgePermissionProps.COLOR_TYPE]: ColorType;
  [BadgePermissionProps.DEFAULT_VALUE]?: any;
}

export enum ColumnSize {
  MEDIUM = 'column-M',
  SMALL = 'column-S',
  VERY_SMALL = 'column-XS',
  LARGE = 'column-L'
}
