import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TestIdConstant } from './test-id.constant';

@Directive({
  selector: '[testIdDataAttr]',
  standalone: true
})
export class TestIdDataAttrDirective implements OnInit {
  @Input() testIdDataAttr: string;

  constructor(private _el: ElementRef) {}

  ngOnInit() {
    this._el.nativeElement.setAttribute(TestIdConstant, this.testIdDataAttr);
  }
}
