import { Pipe, PipeTransform } from '@angular/core';
import { NextDateHelper } from '@utils/core/next-date.helper';

@Pipe({
  name: 'dateIsoString',
  standalone: true
})
export class DateIsoStringPipe implements PipeTransform {
  transform(dateVal: any, hasAdjust = false, isAdjustEnd = false): any {
    if (dateVal) {
      if (hasAdjust) {
        return NextDateHelper.buildAdjustedUTCDateISoString(dateVal, isAdjustEnd);
      }

      return NextDateHelper.buildUTCDateISoString(dateVal);
    }
  }
}
