import { NextRouteProps, RouteParams } from "app/core/enums/route.enum";
import { NextRouteItem } from "app/core/types/route.type";
import { SourceLocationConstant } from "./pages/source-location/constant/source-location.constant";
import { BaseRouteConfig } from "app/core/constants/base.route.config";
import { FormSectionProps } from "@components/organisms/layout/enum/form-section.enum";
import { SourceLocationSection } from "./pages/source-location/enums/source-location.enum";

export class GeneralMaintenanceRoutingConfig {
    static readonly mainPath = 'general-maintenance';
    static readonly sourceLocationPath = 'source-location';

    static readonly mainRoute = {
        [NextRouteProps.ID]: 'GeneralMaintenance',
        [NextRouteProps.LABEL]: 'MENU.GENERAL_MAINTENANCE',
        [NextRouteProps.PATH]: GeneralMaintenanceRoutingConfig.mainPath,
        [NextRouteProps.DISABLED_BREADCRUMB_ITEM]: true
    };
}