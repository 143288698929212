export enum UserLimitsProps {
  WRITE_OFF_LIMITS_ENABLED = 'isWriteOffLimitsEnabled',
  WRITE_OFF_LIMITS_AMOUNT = 'writeOffLimitsAmount',
  WITHHOLDING_TAX_WRITE_OFF_LIMITS_ENABLED = 'isWithholdingTaxWriteOffLimitsEnabled',
  WITHHOLDING_TAX_WRITE_OFF_LIMITS_AMOUNT = 'withholdingTaxWriteOffLimits',
  CAM_DISTRIBUTOR_PORTFOLIO_AMOUNT = 'camDistributorPortfolioAmount',
  DISTRIBUTOR_IDS = 'distributorIds'
}

export enum UserManagerProps {
  ID = 'id',
  CDSID = 'cdsid',
  GIVEN_NAME = 'givenName',
  FAMILY_NAME = 'familyName',
  CREATED_TIMESTAMP = 'createdTimestamp',
  LAST_MODIFIED_TIMESTAMP = 'lastModifiedTimestamp',
  STATUS = 'status',
  ROLES = 'roles',
  WRITE_OFF_ENABLED = 'writeOffEnabled',
  WRITE_OFF_AMOUNT = 'writeOffAmount',
  WRITE_OFF_AMOUNT_CURRENCY = 'writeOffAmountCurrency',
  WITHHOLDING_TAX_WRITE_OFF_ENABLED = 'withholdingTaxWriteOffEnabled',
  WITHHOLDING_TAX_WRITE_OFF_AMOUNT = 'withholdingTaxWriteOffAmount',
  WITHHOLDING_TAX_WRITE_OFF_AMOUNT_CURRENCY = 'withholdingTaxWriteOffAmountCurrency',
  CAM_DISTRIBUTOR_PORTFOLIO_AMOUNT = 'camDistributorPortfolioAmount',
  DISTRIBUTORS = 'distributors',
  DISTRIBUTOR_IDS = 'distributorIds',
  // Extra Fields
  PARAMETERS = 'parameters',
  STATUS_COLOR_TYPE = 'statusColorType',
  STATUS_LABEL_KEY = 'statusLabelKey',
  USER_NAME = 'userName'
}

export enum UserStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  WAITING = 'WAITING APPROVAL',
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
  CLOSED = 'CLOSED'
}

export enum UserPermission {
  READ = 'R',
  WRITE = 'W',
  APPROVE = 'A',
  CREATE_MODIFY = 'M'
}

export enum UserManagerTestIds {
  HEADER = 'user-manage__header',
  FILTER_TOOLBAR = 'user-manager__filter-toolbar',
  TABLE = 'user-manager__table',
  EXPANDIBLE = 'user-manager__expandible-form'
}

export enum UserLimitTestIds {
  SUBMIT_CANCEL = 'user-limits__submit-cancel',
  BUTTON_EDIT = 'user-limits__button-edit',
  PICKLIST = 'user-limits__picklist'
}

export enum UserRole {
  OPERATIONS = 'operations',
  GENERAL_OVERVIEW = 'generalOverview',
  WWTF_ACCOUNTING = 'wwtfAccounting',
  OPERATIONS_SUPERVISOR = 'operationSupervisor',
  CAM = 'cam',
  ACCOUNT_MANAGER = 'accountManager',
  IT_DEV = 'itDev'
}

export enum UserSpecialPermission {
  IT_DEV = 'IT Dev',
  CAM = 'CAM'
}
