import dayjs, { UnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { DateUnits, TimeCompareReference } from '../../../core/enums/date.enum';
import { DateAll } from '../../../core/types/date.type';
import moment from 'moment';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export class NextDateHelper {
  /**
   * Returns date ISO string
   *
   *
   * @param date - the date to convert, defaults to present date
   * @returns date ISO string
   *
   */
  static getDateIsoString(date: Date = new Date()): string {
    return date.toISOString();
  }

  /**
   * Calculates time diff in minutes
   *
   * @param toDate
   * @param fromDate defaults to current date
   * @param timeUnit defaults to Minutes
   * @returns diff in Time Unit
   *
   */
  static calculateTimeDiff(toDate: any, fromDate: any = new Date(), timeUnit = DateUnits.MILISECOND): number {
    return dayjs(toDate).diff(dayjs(fromDate), timeUnit as UnitType);
  }

  static isDate(date: any):number {
    return Date.parse(date);
  }

  static isDateComparedToReference(timeComparison: TimeCompareReference, date: DateAll, referenceDate: DateAll) {
    const dateFn = dayjs(date);
    const comparisonFn = {
      [TimeCompareReference.AFTER]: dateFn.isAfter(dayjs(referenceDate)),
      [TimeCompareReference.BEFORE]: dateFn.isBefore(dayjs(referenceDate)),
      [TimeCompareReference.SAME]: dateFn.isSame(dayjs(referenceDate))
    };
    return comparisonFn[timeComparison];
  }

  static isValidDate(value: any): boolean {
    return !isNaN(+new Date(value));
  }

  /**
   * Returns date's  year
   *
   *
   * @param date - the date to convert, defaults to present date
   * @returns number year
   *
   */
  static getYearFromDate(date: any = new Date()): number {
    const dateVal = new Date(date);

    return dateVal.getUTCFullYear();
  }

  static buildUTCDateFromArguments(year: number, month: number, day = 1): string {
    return new Date(Date.UTC(year, month, day)).toISOString();
  }

  static buildDateWithoutOffset(isoDate: any): Date | null {
    if (!isoDate) {
      return null;
    }
    const date = new Date(isoDate).toISOString();
    return new Date(date.substring(0, date.length - 1));
  }

  static buildYearRangeFromDates(minDate: DateAll = new Date(), maxDate: DateAll = new Date()): number[] {
    const yearRange = [];
    const minYear = new Date(minDate).getFullYear();
    const maxYear = new Date(maxDate).getFullYear();

    for (let i = minYear; i <= maxYear; i++) {
      yearRange.push(i);
    }

    return yearRange;
  }

  static buildMonthRangeFromDates(minDate: DateAll, maxDate: DateAll, year: number): number[] {
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);
    const minYear = minDateObj.getFullYear();
    const maxYear = maxDateObj.getFullYear();

    const minMonth = year === minYear ? minDateObj.getMonth() : 0;
    const maxMonth = year === maxYear ? maxDateObj.getMonth() : 11;

    return [minMonth, maxMonth];
  }

  static buildUTCDateISoString(dateArg: any = new Date(), adjustTimezone = false): string {
    return dayjs(dateArg).utc(!adjustTimezone).toDate().toISOString();
  }

  static adjustDate(date: string | Date, isEnd = false, dateUnit: any = DateUnits.DAY as UnitType): Date {
    const dayJSDate = dayjs(date);
    const adjustedDateJS = isEnd ? dayJSDate.endOf(dateUnit) : dayJSDate.startOf(dateUnit);

    return adjustedDateJS.toDate();
  }

  static buildAdjustedUTCDateISoString(date: string | Date, isEnd?: boolean, unitOfTime?: DateUnits): string {
    const adjustedDate = NextDateHelper.adjustDate(date, isEnd, unitOfTime);
    return NextDateHelper.buildUTCDateISoString(adjustedDate);
  }

  static addUnitToDate(number: number, unit: DateUnits, date = new Date()): Date {
    const dayJsDate = dayjs(date);
    // @ts-expect-error From Common
    return dayJsDate.add(number, unit).toDate();
  }

  static createTodayDateFromHour(hourStr: string): Date {
    return dayjs(hourStr, ['HH:mm:ss', 'HH:mm']).toDate();
  }

  static convertDate(date: string, format = 'DD/MM/YYYY'): any {
    return moment(date).format(format);
  }
}