import { GenericObject } from '@utils/models/Types';
import { DateUnits } from '../enums/date.enum';
import { CalendarViewType } from '@components/atoms/form-inputs/components/next-calendar/enums/calendar.enum';

export class DateConstant {
  static readonly unitOfTimeByCalendarType: GenericObject<DateUnits> = {
    [CalendarViewType.DATE]: DateUnits.DAY,
    [CalendarViewType.MONTH]: DateUnits.MONTH,
    [CalendarViewType.YEAR]: DateUnits.YEAR
  };
}
