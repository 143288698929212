<div class="input-container" [class.input-container--single-row]="isSingleRow">
  <div *ngIf="labelKey || label" class="input-container__label">
    <span class="input-container__label-item"
          *ngIf="(showRequiredSign$ | async) && !hideRequiredSymbol"
          [pTooltip]="'REQUIRED.DESC' | translate"
    >&nbsp;*</span
    >
    <label class="input-container__label-item">{{ (labelKey | translate) || label }}</label>
    <span *ngIf="isFourEye"
          class="input-container__four-eye input-container__label-item">{{'FOUR_EYE_CHECK' | translate}}</span>
  </div>
  <div class="input-container__element">
    <ng-content select="[inputElement]"></ng-content>
  </div>
  <div class="input-container__validation-messages">
    <app-validation-messages
      *ngIf="
        (showValidationMessages && control?.enabled) ||
        (control && showValidationMessagesOnDisabled)
    "
      [control]="control"
      [isAlignedLeft]="true"
      class="input-label__validation"
    >
      <ng-content select="[customValidationMessages]"></ng-content>
    </app-validation-messages>
  </div>
  <div class="input-container__footer">
    <ng-content select="[inputFooter]"></ng-content>
  </div>
</div>
