import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { GenericObject } from '@utils/models/Types';
import { NextInputNumberConfig } from '@components/atoms/form-inputs/components/next-input-number/next-input-number.type';

const amountConfig = {
  useGrouping: true,
  minFractionDigits: 2,
  maxFractionDigits: 2
};

export class InputConstant {
  static readonly numericConfigByType: GenericObject<NextInputNumberConfig> = {
    [InputNumberType.INTEGER]: {
      useGrouping: false,
      mode: 'decimal'
    },
    [InputNumberType.AMOUNT]: {
      ...amountConfig,
      mode: 'decimal'
    },
    [InputNumberType.CURRENCY_AMOUNT]: {
      ...amountConfig,
      currencyDisplay: 'code',
      mode: 'currency'
    }
  };
}
