import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { InputConstant } from '@components/atoms/form-inputs/constants/input.constant';
import { NextInputNumberConfig } from '@components/atoms/form-inputs/components/next-input-number/next-input-number.type';
import { InputNumberInputEvent } from 'primeng/inputnumber';
import { AppSettingsConstant } from '@constants/app-settings.constant';

@Component({
  selector: 'next-input-number',
  templateUrl: './next-input-number.component.html',
  styleUrl: './next-input-number.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextInputNumberComponent implements OnInit {
  @Input() control: FormControl;
  @Input() type: InputNumberType = InputNumberType.INTEGER;
  @Input() currency?: string;
  @Input() disabled: boolean;
  @Input() locale = AppSettingsConstant.defaultLocale;
  @Input() name: string;

  @Output() inputChange: EventEmitter<InputNumberInputEvent> = new EventEmitter<InputNumberInputEvent>();
  config: NextInputNumberConfig;

  ngOnInit(): void {
    this.config = InputConstant.numericConfigByType[this.type];
  }
}
