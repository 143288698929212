import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormConstant } from '@utils/constants/form.constant';
import { NextRadioTestIds } from '@components/atoms/form-inputs/components/next-radio/next-radio.enum';
import { filter, map, merge, of, pairwise } from 'rxjs';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { FormControl } from '@angular/forms';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NextSelectItem } from '../../../../../core/types/select.type';

@Component({
  selector: 'next-radio',
  templateUrl: './next-radio.component.html',
  styleUrl: './next-radio.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextRadioComponent extends GenericBaseComponent implements OnInit, OnChanges {
  @Input() isEmitChangeActive = false;
  @Input() isVertical = false;
  @Input() isFullWidth = false;
  @Input() isBinaryOptions = false;
  @Input() showNullOption = false;
  @Input() nullOptionLabel = 'BINARY_SELECT.false';
  @Input() nullOptionValue = FormConstant.NULL;
  @Input() control: FormControl;
  @Input() readOnlyValue: any;
  @Input() name = crypto.randomUUID();
  @Input() items: NextSelectItem[] = [];
  @Output() valueChange = new EventEmitter();
  @Output() valueChangeEvt = new EventEmitter();

  options: NextSelectItem[] = [];
  readOnlyControl = new FormControl();
  protected readonly NextRadioTestIds = NextRadioTestIds;
  private readonly _binaryItems = [
    { value: true, labelKey: 'BINARY_SELECT.true' },
    { value: false, labelKey: 'BINARY_SELECT.false' }
  ];

  ngOnInit() {
    if (this.isEmitChangeActive && this.control) {
      this._emitValueChange();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] || changes['isBinaryOptions']) {
      this._initializeItems();
    }
  }

  private _initializeItems() {
    const finalOptions = NextObjectHelper.deepClone(
      this.isBinaryOptions ? this._binaryItems : NextValueHelper.defaultValue(this.items, [])
    );
    const nullOption = {
      value: this.nullOptionValue,
      label: this.nullOptionLabel
    };

    if (this.showNullOption) {
      finalOptions.unshift(nullOption);
    }
    this.options = finalOptions;

    if (this.readOnlyValue) {
      this.readOnlyControl.setValue(this.readOnlyValue);
      this.readOnlyControl.disable();
    }
  }

  private _emitValueChange(): void {
    const subs = merge(of(this.control.value), this.control.valueChanges)
      .pipe(
        pairwise(),
        filter(([prev, curr]) => prev !== curr && this.control.enabled),
        map(([, curr]) => curr)
      )
      .subscribe(val => {
        this.valueChange.emit(val);
      });
    this.subs.push(subs);
  }
}
