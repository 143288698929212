import { NextValueHelper } from './next-value.helper';
import { StringTernaryList } from '@utils/models/String';
import { GenericObject, PrimitiveValue } from '@utils/models/Types';
import { StringBuildConfig } from '@utils/enums/string.enum';
import { StringConvertConfig, StringReplaceConfig } from '@utils/models/Value';

export class NextStringHelper {
  static hasPatternMatch(value: string, pattern: string): boolean {
    if (!value || !pattern) {
      return false;
    }
    const regexPattern = new RegExp(pattern);

    return regexPattern.test(value);
  }

  static convertToString(value: PrimitiveValue, config: StringConvertConfig = {}): string | void {
    if (NextValueHelper.isValueDefined(value)) {
      let valStr = `${value}`;
      const { isLowerCase, isUpperCase } = config;

      if (isLowerCase) {
        valStr = valStr.toLowerCase();
      } else if (isUpperCase) {
        valStr = valStr.toUpperCase();
      }

      return valStr;
    }
  }

  static replaceStringKeys(str: string, values: GenericObject = {}, config: StringReplaceConfig = {}): string {
    let res = str;

    Object.keys(values).forEach(paramKey => {
      const key = [config.keyPrefix, paramKey, config.keySuffix].join('');
      res = res.replace(key, values[paramKey]);
    });

    return res.trim();
  }

  static buildStringWithTernaryRules(rules: StringTernaryList = []): string {
    const res = rules.reduce((acc, [condition, trueValue, falseValue]) => {
      const itemValue = NextValueHelper.ternaryOperator(condition, trueValue, falseValue);

      if (itemValue) {
        return acc.concat(` ${itemValue}`);
      }
      return acc;
    }, '');

    return res.trim();
  }

  static buildWrappedString(value: any, config: StringBuildConfig = { prefix: '', suffix: '' }): string {
    const prefix = NextValueHelper.defaultValue(config.prefix, '');
    const suffix = NextValueHelper.defaultValue(config.suffix, '');

    return `${prefix}${value}${suffix}`;
  }
}
