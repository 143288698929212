import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  DropdownItemProps,
  DropdownProps
} from '@components/atoms/form-inputs/components/next-dropdown/enums/dropdown.enum';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { GenericObject } from '@utils/models/Types';
import { DropdownIndexValue } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';

@Component({
  selector: 'abstract-select',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractSelectComponent extends GenericBaseComponent implements OnChanges {
  @Input() control: any;
  @Input() items: SelectItem[] | any[];
  @Input() itemsObs$: Observable<any>;
  @Input() filter = true;
  @Input() placeholder = 'DROPDOWN.PLACEHOLDER';
  @Input() showClear = true;

  @Input() optionLabel?: string;
  @Input() optionValue?: string;

  @Output() changeEvt = new EventEmitter();
  @Output() changeEvtIndex = new EventEmitter<DropdownIndexValue<any>>();

  isLoading: boolean;

  optionLabelKey: string;
  isFixed = false;
  optionList: SelectItem[] = [];
  indexedValues: GenericObject;

  protected _translateService: TranslateService;
  protected _cdr: ChangeDetectorRef;

  constructor(injector$: Injector) {
    super();
    this._translateService = injector$.get(TranslateService);
    this._cdr = injector$.get(ChangeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isLoading = true;
    const { items, itemsObs$ } = changes;
    this.setOptionLabelKey(this.optionLabel);
    if (items) {
      this.handleItems(this.items);
    }

    if (itemsObs$) {
      this.handleItemObs();
    }
  }

  setOptionLabelKey(optionLabelKey?: string) {
    this.optionLabelKey = optionLabelKey || DropdownItemProps.LABEL;
  }

  handleItems(items: any): void {
    this.setOptionList(items);

    if (items && !this.control.value) {
      // TODO implement auto select
    }
  }

  handleItemObs() {
    // TODO Review how obs works in here
    const subs = this.itemsObs$.subscribe({
      next: (resp: any) => {
        let list = resp;
        const isIndexedResp = NextObjectHelper.isObject(resp);
        if (isIndexedResp) {
          this.indexedValues = NextObjectHelper.getPropertyFromObject(resp, DropdownProps.VALUES);
          list = NextObjectHelper.getPropertyFromObject(resp, DropdownProps.LIST);
        }
        this.handleItems(list);

        this._cdr.markForCheck();
      }
    });

    this.subs.push(subs);
  }

  setOptionList(items: any[]): void {
    let computedItems = items;
    this.isFixed = false;
    const isEmpty: boolean = !!items && items.length === 0;

    if (isEmpty) {
      const emptyLabel = this._translateService.instant('DROPDOWN.NO_ITEMS');
      const label = `- ${emptyLabel} -`;
      computedItems = [{ label, value: undefined, disabled: true }];

      // TODO Implement
      /*if (this.isBinaryDefault) {

        // items = this._dropdownService.buildBinaryDropdownList();
      }*/
    }
    this.optionList = computedItems;
    this.isLoading = false;
  }

  handleChange(item: SelectItem) {
    const valueId = item?.value;
    this.changeEvt.emit(valueId);
    if (valueId) {
      this.changeEvtIndex.emit({
        [DropdownProps.ENTITY]: NextObjectHelper.getPropertyFromObject(this.indexedValues, valueId),
        [DropdownProps.VALUE]: valueId
      });
    } else {
      this.changeEvtIndex.emit(valueId);
    }
  }
}
