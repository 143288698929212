import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';

@Component({
  selector: 'next-icon',
  templateUrl: './next-icon.component.html',
  styleUrl: './next-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextIconComponent {
  @Input() iconType: NextIcon;
  @Input() color?: IconColor = IconColor.BRAND_PRIMARY;
  @Input() size?: IconSizeModifier = IconSizeModifier.REGULAR;
}
