<div class="date-from-to">
  <next-calendar [control]="fromControl" [defaultDateValue]="defaultFromDate | dateIsoString : true"
                 [maxDate]="(toControl.value | dateObject) || maxDate"
                 [minDate]="minDate"
                 [placeHolder]="'FROM' | translate"
                 [viewMode]="viewMode" (selectEvt)="handleFromChange($event)"
                 [styleClass]="styleClass"
                 [appendTo]="appendTo"></next-calendar>
  <next-calendar [control]="toControl" [defaultDateValue]="defaultToDate | dateIsoString : true"
                 [maxDate]="maxRangeDate || maxDate"
                 [minDate]="(fromControl.value | dateObject) || minDate"
                 [placeHolder]="'TO' | translate"
                 [viewMode]="viewMode" (selectEvt)="handleValueChange($event, DateFromToControls.TO)"
                 [styleClass]="styleClass"
                 [appendTo]="appendTo"></next-calendar>
</div>
