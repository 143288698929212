import { Pipe, PipeTransform } from '@angular/core';
import { GenericObject } from '@utils/models/Types';
import { NextObjectHelper } from '@utils/core/next-object.helper';

@Pipe({
  name: 'safeGuardObjectProperty'
})
export class SafeGuardObjectPropertyPipe implements PipeTransform {
  transform(value: GenericObject | undefined, pathKeyList: any[] | string, defaultValue?: any): any {
    return NextObjectHelper.getPropertyFromObject(value, pathKeyList, defaultValue);
  }
}
