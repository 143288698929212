import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DropdownConfig,
  DropdownList
} from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import {
  DropdownConfigProps,
  DropdownProps,
  genericDefaultItemProp
} from '@components/atoms/form-inputs/components/next-dropdown/enums/dropdown.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DropdownHelper } from '@components/atoms/form-inputs/components/next-dropdown/helpers/dropdown.helper';
import { EndPointItem, HttpOptions } from '@wwtfTypes/backend.type';
import { BackendService } from '@services/backend.service';
import { map, Observable } from 'rxjs';
import { ProductLineTypes } from '@pages/distributor/types/product-line-blankets.type';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  constructor(
    private _translateService: TranslateService,
    private _backendService: BackendService
  ) {}

  getDropdownTranslationList(
    endpointItem: EndPointItem,
    dropdownConfig?: DropdownConfig,
    httpOptions?: HttpOptions
  ): Observable<DropdownList<ProductLineTypes>> {
    return this._backendService
      .get(endpointItem, httpOptions)
      .pipe(map((items: any) => this.buildDropdownGenericTranslationList(items, dropdownConfig)));
  }

  buildDropdownGenericTranslationList(listArg: any[], config?: DropdownConfig): DropdownList {
    const configWithAppDefaults = this._buildConfigWithAppDefaults(config);

    return DropdownHelper.buildDropdownList(listArg, configWithAppDefaults);
  }

  getDropdownItem(label:string, value:string) {
    return {
      [DropdownProps.VALUE]: value,
      [DropdownProps.LABEL]: label
    }
  }

  private _buildConfigWithAppDefaults(config: DropdownConfig = {}): DropdownConfig {
    const defaultConfig: any = {
      [DropdownConfigProps.DEFAULT_PROP]: genericDefaultItemProp
    };

    if (config[DropdownConfigProps.HAS_TRANSLATION]) {
      defaultConfig[DropdownConfigProps.TRANSLATE_CB] = this._translateCb.bind(this);
    }

    return { ...defaultConfig, ...NextObjectHelper.deepClone(config)};
  }

  private _translateCb(key: string): string {
    return this._translateService.instant(key);
  }
}
