import { Pipe, PipeTransform } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { IconHelper } from '@components/atoms/visual/helpers/icon.helper';

@Pipe({
  name: 'iconClass'
})
export class IconClassPipe implements PipeTransform {
  transform(iconId: NextIcon, color?: IconColor, iconSize?: IconSizeModifier): any {
    return IconHelper.createIconClass(iconId, color, iconSize);
  }
}
