import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusMap } from '../../../../../core/types/status.type';
import { StatusConfigProps } from '../../../../../core/enums/status.enum';
import { GenericObject } from '@utils/models/Types';

@Component({
  selector: 'tag-status',
  templateUrl: './tag-status.component.html',
  styleUrl: './tag-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagStatusComponent {
  @Input() statusMap: GenericObject<StatusMap>;
  @Input() status: any;

  protected readonly StatusConfigProps = StatusConfigProps;
}
