import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { FormItem } from '@utils/models/Form';
import { merge, of } from 'rxjs';
import { NextDateHelper } from '@utils/core/next-date.helper';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractCalendarComponent extends GenericBaseComponent implements OnInit {
  @Input() control: FormItem;
  @Input() defaultDateHighlight: string;
  @Input() defaultDateValue: string;
  @Input() defaultToCurrentDate = false;
  @Input() placeHolder = 'DEFAULT_CALENDAR_PLACEHOLDER'; // This should come from ENUM Formater ?
  @Input() appendTo: any;

  @Output() selectEvt = new EventEmitter<any>();

  dateModel: Date | null;
  isTimeOnly = false;

  ngOnInit() {
    if (this.defaultDateValue || this.defaultToCurrentDate) {
      const dateValueISO = NextDateHelper.getDateIsoString();
      const defDate = this.defaultToCurrentDate ? dateValueISO : this.defaultDateValue;

      this.control.setValue(defDate, { emitEvent: false });
      this.control.markAsDirty();
    }

    this._setValueModel();
  }

  protected handleSelectionBase(dateString: string | null): void {
    this.control.setValue(dateString);
    this.control.markAsDirty();
    this.selectEvt.emit(dateString);
  }

  private _setValueModel(): void {
    const subs = merge(of(this.control.value), this.control.valueChanges).subscribe(item => {
      let date: Date | null = null;

      if (!!item) {
        date = this.isTimeOnly
          ? NextDateHelper.createTodayDateFromHour(item)
          : NextDateHelper.buildDateWithoutOffset(item);
      }
      this.dateModel = date;
    });

    this.subs.push(subs);
  }
}
