import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractCalendarComponent } from '@components/atoms/form-inputs/components/abstract/abstract-calendar/abstract-calendar.component';
import { Calendar } from 'primeng/calendar';
import { CalendarViewType } from '@components/atoms/form-inputs/components/next-calendar/enums/calendar.enum';
import { TranslateService } from '@ngx-translate/core';
import { NextDateHelper } from '@utils/core/next-date.helper';
import { DateSelectionConstant } from '../../../../../core/constants/date-selection.constant';
import { GenericObject } from '@utils/models/Types';

@Component({
  selector: 'next-calendar',
  templateUrl: './next-calendar.component.html',
  styleUrl: './next-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextCalendarComponent extends AbstractCalendarComponent implements OnInit {
  @Input() viewMode = CalendarViewType.DATE;
  @Input() showButtonBar = true;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() styleClass?: string = 'form__input';

  @ViewChild('primeCalendar', { static: false }) calendarEl: Calendar;

  locale: GenericObject;
  dateFormat: string;

  constructor(private _translateService: TranslateService) {
    super();
  }

  override ngOnInit() {
    this.locale = this._translateService.instant('CALENDAR');
    super.ngOnInit();
    this.dateFormat = DateSelectionConstant.dateFormatByType[this.viewMode];
  }

  handleSelection(date?: any): void {
    const dateString = !!date ? NextDateHelper.buildUTCDateISoString(date) : null;
    this.handleSelectionBase(dateString);
  }
}
