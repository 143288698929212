export enum CalendarViewType {
  MONTH = 'month',
  DATE = 'date',
  YEAR = 'year'
}

export enum CalendarPrimeDateFormat {
  DATE = 'dd/mm/yy',
  MONTH = 'mm/yy',
  YEAR = 'yyyy'
}
