<div class="form-menu">
  <ul class="form-menu__list">
    <li
      *ngFor="let item of formSectionList"
      (click)="sectionClickHandle(item)"
      class="form-menu__list-item"
      [class.form-menu__list-item--completed]="
        formSectionConfig[item][FormSectionProps.STATUS] === FormSectionStatus.COMPLETED
      "
      [class.form-menu__list-item--disabled]="
        formSectionConfig[item][FormSectionProps.STATUS] === FormSectionStatus.DISABLED
      "
      [class.form-menu__list-item--active]="item === activeFormSection"
    >
      <span>{{ formSectionConfig[item][FormSectionProps.LABEL_KEY] | translate }}</span>
    </li>
  </ul>
  <div class="form-menu__finish">
    <next-button
      [labelKey]="'DISTRIBUTOR.MANAGER.FORM.SUBMIT_SEND_TO_APPROVAL'"
      [isDisabled]="isDisabledFinishButton"
      (clickEvent)="submitClick.emit()"
    ></next-button>
  </div>
</div>
