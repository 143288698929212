import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ColorType } from '../../../../../shared/enums/color.enum';
import { BadgeConstant } from '@constants/badge.constant';
import { StatusConfigProps } from 'app/core/enums/status.enum';

@Component({
  selector: 'next-badge',
  templateUrl: './next-badge.component.html',
  styleUrl: './next-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextBadgeComponent implements OnInit{
  @Input() value: any;
  @Input() statusMap: any;
  @Input() colorType: ColorType;
  @Input() isColorFilter: boolean = false;
  
  statusBadge: any;
  
  protected readonly BadgeConstant = BadgeConstant;
  protected readonly StatusConfigProps = StatusConfigProps;
  
  ngOnInit() {
    if(this.value) {
      this.statusBadge  = this.value.status;
    }
    if( this.statusMap && this.value) {
      this.colorType = this.statusMap[this.value?.status].colorType
    }
  }
}
