import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { GenericObject } from '@utils/models/Types';
import { FormSectionProps, FormSectionStatus } from '@components/organisms/layout/enum/form-section.enum';

@Component({
  selector: 'form-menu-section',
  templateUrl: './form-menu-section.component.html',
  styleUrl: './form-menu-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormMenuSectionComponent {
  @Input() formSectionList: any[];
  @Input() formSectionConfig: GenericObject<FormSection>;
  @Input() activeFormSection: any;
  @Input() isDisabledFinishButton: boolean;

  @Output() sectionClick: EventEmitter<FormSection> = new EventEmitter<FormSection>();
  @Output() submitClick: EventEmitter<FormSection> = new EventEmitter<any>();

  protected readonly FormSectionProps = FormSectionProps;
  protected readonly FormSectionStatus = FormSectionStatus;

  sectionClickHandle(item: any) {
    const section = this.formSectionConfig[item];
    if (this.activeFormSection !== item && section?.status !== FormSectionStatus.DISABLED) {
      this.sectionClick.emit(item);
    }
  }
}
