import { AuthConstant } from '@constants/auth.constant';
import { NextRouteItem } from '../../types/route.type';

export class AuthRouteConfig {
  static readonly oauth: NextRouteItem = {
    path: AuthConstant.oAuthUrl,
    hideBreadcrumbItem: true,
    label: 'LOGIN.NAV'
  };

  static readonly oAuthCallbackUrl = AuthConstant.oAuthUrl;
}
