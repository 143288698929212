export enum UserSessionKeys {
  ACCESS_TOKEN = 'accessToken',
  ID_TOKEN = 'idToken',
  REDIRECT_URL = 'redirectUrl',
  NONCE = 'nonce'
}

export enum OAuthCallbackParams {
  ACCESS_TOKEN = 'access_token',
  EXPIRES_IN = 'expires_in',
  ID_TOKEN = 'id_token',
  JTI = 'jti',
  TOKEN_EXP = 'token_exp',
  TOKEN_TYPE = 'token_type'
}

export enum AccessTokenProperties {
  USERNAME = 'sub',
  EXPIRE_NUMERIC_DATE = 'exp',
  NONCE = 'nonce'
}
