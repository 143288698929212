import { GenericObject } from '@utils/models/Types';

export class NextBooleanHelper {
  /**
   * returns boolean from string
   *
   * @param str
   * @returns boolean
   *
   */
  static parseBooleanString(str: string): boolean {
    const stringToBoolMap: GenericObject = {
      true: true,
      false: false
    };
    const strFormatted = `${str}`.toLowerCase();

    return stringToBoolMap[strFormatted];
  }

  static isBoolean(val: any): boolean {
    return val === false || val === true;
  }

  static convertBooleanToString(str1: string, str2:string, field: string, obj: any []) {
    return obj.filter(data => data[field] ? data[field] = str1 : data[field] =str2);
  }
}
