<div
  class="next-radio"
  [class.next-radio--full-width]="isFullWidth"
  [class.next-radio--vertical]="isVertical"
  [testIdDataAttr]="NextRadioTestIds.MAIN"
>
  <p-radioButton *ngFor="let option of options; index as index"
                 [formControl]="control || readOnlyControl"
                 [label]="(option.labelKey | translate) || option.label"
                 [name]="name"
                 [value]="option.value"
                 (onClick)="valueChangeEvt.emit(control.value)"
                 [testIdDataAttr]="NextRadioTestIds.MAIN"
                 class="next-radio__item"
  >

  </p-radioButton>
</div>
