import { Pipe, PipeTransform } from '@angular/core';
import { FormControlStatus } from '@angular/forms';

@Pipe({
  name: 'isDisabledFromControlStatus'
})
export class IsDisabledFromControlStatusPipe implements PipeTransform {
  transform(value: FormControlStatus): boolean {
    return value !== 'VALID';
  }
}
