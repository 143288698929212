import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { IconHelper } from '@components/atoms/visual/helpers/icon.helper';
import { NextMenuItem } from '@wwtfTypes/menu.type';
import { IconSizeModifier } from '@components/atoms/visual/enums/icon.enum';

@Component({
  selector: 'split-button',
  templateUrl: './split-button.component.html',
  styleUrl: './split-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitButtonComponent implements OnInit {
  @Input() labelKey = 'BUTTONS.ACTIONS';
  @Input() label?: string;
  @Input() actionList: NextMenuItem[] = [];

  model: MenuItem[];

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    this.model = this.actionList.map((action: NextMenuItem) => {
      const label: string = NextValueHelper.defaultValue(action.labelKey, '');
      const icon: string = action.nextIcon
        ? IconHelper.createIconClass(action.nextIcon, undefined, IconSizeModifier.SMALL)
        : NextValueHelper.defaultValue(action.icon, '');
      return { ...action, label: this._translateService.instant(label), icon };
    });
  }
}
