import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { dateStandart } from '@constants/time.constant';
import { PipeConfig } from '../../core/types/pipes.type';
import { PipeListFormatPipe } from '@pipes/common/pipe-list-format.pipe';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { FieldType, TableField } from '@components/organisms/next-table/next-table.type';

@Pipe({
  name: 'getValue'
})
export class GetValuePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private _pipeListFormat: PipeListFormatPipe
  ) {}

  transform(object: any = {}, structure: TableField): unknown {
    let value: any;
    if (Array.isArray(structure.field)) {
      const values: string[] = structure.field.map((field: any, index: number) => {
        const currentValue = NextObjectHelper.getPropertyFromObject(object, field);
        const pipeList = NextObjectHelper.getPropertyFromObject(structure, [TableFieldProps.PIPE_LIST, index]);
        if (structure[TableFieldProps.PIPE_LIST]) {
          return this._pipeListFormat.transform(currentValue, pipeList, object);
        }
        return currentValue;
      });
      value = values.join(structure.separator ?? ' ');
    } else {
      value = NextObjectHelper.getPropertyFromObject(object, structure.field);
      if (structure[TableFieldProps.PIPE_LIST]) {
        value = this._pipeListFormat.transform(value, structure[TableFieldProps.PIPE_LIST] as PipeConfig[], object);
      }
    }
    if (structure[TableFieldProps.FIELD_TYPE]) {
      structure.types.forEach(type => {
        switch (type) {
          case FieldType.DATE:
            value = this.datePipe.transform(value, dateStandart);
            break;
          case FieldType.ARRAY:
            value = value.join(structure.separator ?? ' ');
            break;
        }
      });
    }
    return value;
  }
}
