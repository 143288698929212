import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorType } from '../../../../../shared/enums/color.enum';
import { BadgeConstant } from '@constants/badge.constant';

@Component({
  selector: 'next-toggle-button',
  templateUrl: './next-toggle-button.component.html',
  styleUrl: './next-toggle-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextToggleButtonComponent {
  @Input() control: any;
  @Input() onLabel: string;
  @Input() offLabel?: string;
  @Input() colorType: ColorType = ColorType.NEUTRAL;

  @Output() changeToggle: EventEmitter<boolean | undefined> = new EventEmitter<any>();
  protected readonly BadgeConstant = BadgeConstant;
}
