export const genericDefaultItemProp = 'defaultValue';

export enum DropdownProps {
  LIST = 'list',
  VALUES = 'values',
  IS_FIXED = 'isFixed',
  IS_DEFAULT = 'isDefault',
  IS_DISABLED = 'disabled',
  VALUE = 'value',
  LABEL = 'label',
  ENTITY = 'entity',
  ICON = 'icon'
}

export enum DropdownConfigProps {
  VALUE_PROP = 'valueProp',
  LABEL_PROP = 'labelProp',
  ICON = 'icon',
  ICON_PROP = 'iconProp',
  DEFAULT_PROP = 'defaultProp',
  DEFAULT_VALUE = 'defaultValue',
  KEEP_SORTING = 'keepSorting',
  IS_DESCENDING_SORT = 'isDescendingSort',
  LABEL_EXTRA_PROP = 'labelExtraProp',
  LABEL_MAPPING = 'labelMapping',
  LABEL_TRANSLATION_PREFIX = 'labelTranslPrefix',
  HAS_TRANSLATION = 'hasTranslation',
  DISABLED_CB = 'disabledCb',
  IS_FIXED_PROP = 'isFixedProp',
  TRANSLATE_CB = 'translateCb'
}

export enum DropdownTestId {
  MAIN = 'generic-dropdown__main',
  ITEM_ICON = 'generic-dropdown__item-icon',
  READ_ONLY = 'generic-dropdown__read-only',
  ITEM_LABEL = 'generic-dropdown__label',
  INPUT = 'generic-dropdown__input',
  SELECTED_ITEM = '.p-dropdown-label',
  PANEL_CLASS = 'generic-dropdown__panel'
}

export enum DropdownItemProps {
  LABEL = 'label'
}
