import { formatNumber } from '@angular/common';
import { NextValueHelper } from './next-value.helper';
import { AppSettingsConstant } from '@constants/app-settings.constant';

export class NextNumberHelper {
  /**
   * Calculates time diff in minutes
   *
   *
   * @param val number
   * @param precision number of decimals; defaults to current date
   * @returns rounded number
   *
   */
  static roundFloatToPrecision(val: number, precision = 2): number {
    return Number(Math.round(Number(val + 'e' + precision)) + 'e-' + precision);
  }

  static swapCommaByDot(value: string): string {
    return `${value}`.replace(/,/g, '.');
  }

  static isConvertibleToNumber(val: any): boolean {
    const numVal = Number(val);

    return !isNaN(numVal);
  }

  static convertToNumber(val: any): number | void {
    if (NextValueHelper.isValueDefined(val) && NextNumberHelper.isConvertibleToNumber(val)) {
      return Number(val);
    }
  }

  static isBiggerThan(value: number, max: number, includeEqual = false) {
    if (includeEqual) {
      return value >= max;
    }
    return value > max;
  }

  static amountReduce(amountList: any[], amountProp: any) {
    return amountList.reduce((accAmount, currentSecurity) => {
      return accAmount + currentSecurity[amountProp];
    }, 0);
  }

  static currencyFormat(value: any, currencyCode: string = '', defaultToZero = false): unknown {
    let amountValue = value;
    const isDefined = NextValueHelper.isValueDefined(amountValue);

    if (!isDefined) {
      if (!defaultToZero) {
        return '';
      }

      amountValue = 0;
    }

    //Locale to achieve currency format 12.345,66
    const currencyLocale = AppSettingsConstant.defaultLocale;
    const formatedNumber = formatNumber(amountValue, currencyLocale);
    return `${formatedNumber} ${currencyCode}`;
  }
}
