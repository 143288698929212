export enum CreditLimitStatus {
  ACTIVATED = 'A',
  DEACTIVATED = 'D',
  SUSPENDED = 'S'
}

export enum StatusConfigProps {
  KEY = 'statusKey',
  COLOR = 'colorType'
}

export enum OperationalStatus {
  ACTIVE = 'ACTIVE',
  CREDIT_CONCERN = 'CREDIT_CONCERN',
  CREDIT_WATCH = 'CREDIT_WATCH',
  STATUS = 'STATUS',
  STOP_SHIPMENT = 'STOP_SHIPMENT',
  FULL_FINANCIAL_HOLD = 'FULL_FINANCIAL_HOLD',
  EXCEPTION = 'EXCEPTION',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
  INCOMPLETE = 'INCOMPLETE'
}

export enum ProductLineType {
  VEH = 'VEH',
  PAR = 'PAR',
  LIN = 'LIN',
  EXT = 'EXT'
}
