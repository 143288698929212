export enum FormSectionProps {
  ID = 'id',
  LABEL_KEY = 'labelKey',
  STATUS = 'status',
  PATH = 'path',
  TEMPLATE = 'template'
}

export enum FormSectionStatus {
  COMPLETED = 'completed',
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}
