import { debounceTime, filter, Observable, Subscription } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { AppSettingsConstant } from '@constants/app-settings.constant';

export class NextObservableHelper {
  static pipeFormValueChanges(
    control: AbstractControl,
    delayMs = AppSettingsConstant.formValueChangesDebounceMS,
    emitInvalid = false
  ): Observable<any> {
    return control.valueChanges.pipe(
      debounceTime(delayMs),
      filter(() => control.valid || emitInvalid)
    );
  }

  static unsubscribeSubscriptions(subscriptionList: Subscription[] = []): void {
    subscriptionList.forEach(subscription => subscription.unsubscribe());
  }
}
