import { Pipe, PipeTransform } from '@angular/core';
import { DeepControlPipe } from './deep-control.pipe';
import { EMPTY, Observable } from 'rxjs';
import { FormItem, FormValueChangeConfig } from '@utils/models/Form';
import { NextFormHelper } from '@utils/core/next-form.helper';

@Pipe({
  name: 'deepControlValue'
})
export class DeepControlValuePipe implements PipeTransform {
  constructor(private _deepControlPipe: DeepControlPipe) {}

  transform(formGroup: FormItem, props: (string | number)[], configArg?: FormValueChangeConfig): Observable<any> {
    const config = Object.assign({ emitInitialValue: true }, configArg);
    const control = this._deepControlPipe.transform(formGroup, props);

    if (!control) {
      return EMPTY;
    }

    return NextFormHelper.createValueChangeObs(control, config);
  }
}
