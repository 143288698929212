export enum ButtonType {
  PRIMARY = 'button--primary',
  SECONDARY = 'button--secondary',
  CONTRAST = 'button--contrast'
}

export enum ButtonSeverity {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  HELP = 'help',
  DANGER = 'danger',
  CONTRAST = 'contrast'
}

export enum ButtonSize {
  LARGE = 'button--large',
  MEDIUM = 'next-button--medium',
  SMALL = 'button--small',
  X_SMALL = 'button--x-small'
}

export enum ButtonColor {
  BLUE = 'button--blue',
  BLACK = 'button--black',
  GREEN = 'button--green',
  RED = 'button--red'
}
