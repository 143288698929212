<div
  class="column-pair"
  [ngClass]="isVertical ? 'column-pair--vertical' : 'column-pair--horizontal'"
>
  <section class="column-pair__item">
    <ng-content select="[slot1]"></ng-content>
  </section>
  <div *ngIf="!isVertical" class="column-pair__gap"></div>
  <section class="column-pair__item">
    <ng-content select="[slot2]"></ng-content>
  </section>
</div>
