import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'next-card',
  templateUrl: './next-card.component.html',
  styleUrl: './next-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextCardComponent {
  @Input() hasBorder: boolean = false;
  @Input() hasBackgroundColor: boolean = false;
  @Input() isHorizontalContent: boolean = false;
  @Input() hasInnerBorder: boolean = false;
  @Input() hasNoneBorderRadius: boolean = false;
  @Input() header?: string;
  @Input() headerKey?: string;
  @Input() subHeader?: string;
  @Input() subHeaderKey?: string;
}
