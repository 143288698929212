import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { GenericObject } from '@utils/models/Types';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';

@Component({
  selector: 'data-card',
  templateUrl: './data-card.component.html',
  styleUrl: './data-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCardComponent implements OnInit {
  @Input() dataObject: GenericObject | any;
  @Input() dataObjectRef?: GenericObject | any;
  @Input() config?: DataTitleConfig;
  @Input() configList?: DataTitleConfig[];
  @Input() hasBorderCard: boolean = false;
  @Input() isHorizontalContent: boolean = false;
  @Input() hasBorderCardRadius: boolean = false;
  @Input() isHorizontalData: boolean = false;
  @Input() subTitle?: string;
  @Input() subTitleKey?: string;

  dataTitleConfigList: DataTitleConfig[] = [];

  ngOnInit(): void {
    this._setData();
  }

  private _setData() {
    if (this.config) {
      this.dataTitleConfigList = [{ ...this.config }];
    } else if (this.configList) {
      this.dataTitleConfigList = [...this.configList];
    }
  }

  protected readonly DataTitleProps = DataTitleProps;
}
