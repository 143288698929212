import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { IconHelper } from '@components/atoms/visual/helpers/icon.helper';
import { AuthRouteConfig } from '../../core/modules/auth/auth.route.config';
import { UserSessionKeys } from '../enums/auth.enum';
import { CommonRoutingService } from '../../core/services/common-routing.service';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NavigationConfig } from '@wwtfTypes/navigation.type';
import { NavigationConfigProps } from '../enums/navigation.enum';
import { NextRouteItem } from '../../core/types/route.type';
import { NextStringHelper } from '@utils/core/next-string.helper';
import { RoutingConfigConstant } from '@constants/routing.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { NextRouteProps } from '../../core/enums/route.enum';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { MeService } from '../../core/services/me.service';
import { MeProps } from '../../core/enums/me.enum';
import { GenericObject } from '@utils/models/Types';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _mainMenuOpenStatusSubject = new BehaviorSubject(false);

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _commonRoutingService: CommonRoutingService,
    private _meService: MeService
  ) {}

  getMainMenuOpenStatusObs() {
    return this._mainMenuOpenStatusSubject;
  }

  setOpenMenuStatus(isOpen = false) {
    this._mainMenuOpenStatusSubject.next(isOpen);
  }

  buildMenu(nav: any[]): MenuItem[] {
    const meSession = this._meService.getMeSession();
    return nav
      .filter(routeConfig => this._isFeatureVisible(routeConfig, meSession[MeProps.PERMISSIONS]))
      .map((routeConfig: any) => {
        const menuItem = this._buildMenuItem(routeConfig);
        if (routeConfig.items) {
          menuItem.items = this.buildMenu(routeConfig.items);
        }

        return menuItem;
      });
  }

  storeRedirectUrl(currentUrl = ''): void {
    const url = currentUrl.toLowerCase();
    if (url.indexOf(AuthRouteConfig.oAuthCallbackUrl) === -1 && url !== '') {
      sessionStorage.setItem(UserSessionKeys.REDIRECT_URL, currentUrl);
    }
  }

  removeRedirectUrl(): void {
    sessionStorage.removeItem(UserSessionKeys.REDIRECT_URL);
  }

  getStoredUrl(): string | null {
    return sessionStorage.getItem(UserSessionKeys.REDIRECT_URL);
  }

  navigateToRouteConfig(routeItem: NextRouteItem, config: NavigationConfig = {}) {
    const url = this.buildConfigItemRoute(routeItem, config);
    if (url) {
      this.setOpenMenuStatus();
      return this._commonRoutingService.navigateByUrlWrapper(
        url,
        NextObjectHelper.getPropertyFromObject(config, [NavigationConfigProps.IS_NEW_TAB], false)
      );
    } else {
      return Promise.reject();
    }
  }

  navigateToSibling(path: any[], activatedRoute: ActivatedRoute) {
    this.setOpenMenuStatus();
    return this._router.navigate(path, { relativeTo: activatedRoute.parent });
  }

  buildConfigItemRoute(routeItem: NextRouteItem, config: NavigationConfig = {}): string | undefined {
    const params = config[NavigationConfigProps.URL_PARAMS];
    const queryParams = config[NavigationConfigProps.QUERY_PARAMS];
    const isAbsoluteUrl = !!config[NavigationConfigProps.IS_ABSOLUTE_URL];

    if (!routeItem) {
      return;
    }

    let url = this._buildUrlFromConfig(routeItem, undefined, config[NavigationConfigProps.ROUTE_PARENT]);

    if (!!params) {
      url = NextStringHelper.replaceStringKeys(url, params, {
        keyPrefix: RoutingConfigConstant.routeParamPrefix
      });
    }

    if (!!queryParams) {
      url = this._router.serializeUrl(this._router.createUrlTree([url], { queryParams }));
    }

    if (isAbsoluteUrl) {
      url = `${window.location.origin}/${url}`;
    }

    return url;
  }

  private _buildMenuItem(routeConfig: any): MenuItem {
    return {
      expanded: false,
      label: this._translateService.instant(routeConfig.label),
      icon: IconHelper.createIconClass(NextValueHelper.defaultValue(routeConfig[NextRouteProps.ICON])),
      routerLink: this.buildConfigItemRoute(routeConfig),
      command: !routeConfig.items ? () => this.setOpenMenuStatus(false) : undefined,
      automationId: routeConfig[NextRouteProps.ID]
    };
  }

  private _isFeatureVisible(menuItem: any, permission: GenericObject) {
    if (NextValueHelper.isValueDefined(menuItem[NextRouteProps.PERMISSION])) {
      const permissionMap = menuItem[NextRouteProps.PERMISSION];
      return permissionMap.reduce((acc: boolean, key: any) => {
        const hasPermission = permission[key];
        return acc || hasPermission;
      }, false);
    }
    return true;
  }

  private _buildUrlFromConfig(routeConfig: NextRouteItem, urlAcc = '', selectedParent?: any[]): string {
    const { path, parent } = routeConfig;
    const url = urlAcc.length ? `${path}/${urlAcc}` : path;

    if (parent) {
      return this._buildUrlParent(url, parent, selectedParent)!;
    }

    return url;
  }

  private _buildUrlParent(url: string, parent: NextRouteItem | NextRouteItem[], selectedParent?: any) {
    if (Array.isArray(parent)) {
      if (!selectedParent) {
        this._throwNoParentError();
        return '';
      } else {
        for (const item of parent) {
          if (NextObjectHelper.isEqual(item, selectedParent)) {
            return this._buildUrlFromConfig(item, url, selectedParent);
          }
        }
        this._throwNoParentError();
        return '';
      }
    } else {
      return this._buildUrlFromConfig(parent, url, selectedParent);
    }
  }

  private _throwNoParentError() {
    throw new Error('No parent provided for route item with multiple parents');
  }
}
