import { GenericFunction } from '@utils/models/Types';
import { NextBrowserConstant } from '@constants/browser.constant';

export class NextBrowserHelper {
  /**
   * Scrolls element to id, using defined scroll config
   *
   * @param id
   * @param scrollConfig
   *
   */
  static scrollToId(id: string, scrollConfig?: ScrollIntoViewOptions): void {
    const element = document.getElementById(id);

    NextBrowserHelper.scrollToHtmlElement(element, scrollConfig);
  }

  /**
   * Scrolls element to first element with class item, using defined scroll config
   *
   * @param query
   * @param parentElement
   * @param scrollConfig
   *
   */
  static scrollToElementByQuery(
    query: string,
    parentElement?: HTMLElement,
    scrollConfig?: ScrollIntoViewOptions
  ): void {
    const container = parentElement || document;
    const element = container.querySelector(query);

    NextBrowserHelper.scrollToHtmlElement(element!, scrollConfig);
  }

  static scrollToHtmlElement(element: Element | null, scrollConfig?: ScrollIntoViewOptions): void {
    const config = Object.assign(NextBrowserConstant.scrollDefaultConfig, scrollConfig);

    if (element) {
      element.scrollIntoView(config);
    }
  }

  /**
   * Sets local storage item
   *
   * @param key
   * @param value
   *
   */
  static setLocalStorageItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  static getLocalStorageItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Sets session storage item
   *
   * @param key
   * @param value
   *
   */
  static setSessionStorageItem(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  static getSessionStorageItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  static openNewTab(url: string): void {
    window.open(url, '_blank');
  }

  static copyToClipBoard(value: any, successCallback?: GenericFunction, errorCallback?: GenericFunction) {
    navigator.clipboard.writeText(value).then(
      () => {
        if (successCallback) {
          successCallback();
        }
      },
      () => {
        if (errorCallback) {
          errorCallback();
        }
      }
    );
  }
}
