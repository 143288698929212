export enum MeProps {
  CDSID = 'cdsid',
  GIVEN_NAME = 'givenName',
  FAMILY_NAME = 'familyName',
  CREATION_TIMESTAMP = 'creationTimestamp',
  LAST_MODIFIED_TIMESTAMP = 'lastModifiedTimestamp',
  STATUS = 'status',
  ROLES = 'roles',
  DEALERS = 'dealers',
  PERMISSIONS = 'permissions',
  WRITEOFF_ENABLED = 'writeOffEnabled',
  WRITEOFF_AMOUNT = 'writeOffAmount',
  WRITEOFF_AMOUNT_CURRENCY = 'writeOffAmountCurrency',
  WITHHOLDING_TAX_WRITEOFF_ENABLED = 'withholdingTaxWriteOffEnabled',
  WITHHOLDING_TAX_WRITEOFF_AMOUNT = 'withholdingTaxWriteOffAmount',
  WITHHOLDING_TAX_WRITEOFF_AMOUNT_CURRENCY = 'withholdingTaxWriteOffAmountCurrency'
}
