import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { NextFormHelper } from '@utils/core/next-form.helper';

@Pipe({
  name: 'disabledChange'
})
export class DisabledChangePipe implements PipeTransform {
  transform(control: AbstractControl): Observable<boolean> {
    return NextFormHelper.createDisabledChangeObs(control);
  }
}
