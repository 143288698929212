export enum NextIcon {
  CHECKMARK = 'checkmark',
  CROSS = 'cross',
  FILTER = 'filter',
  DISTRIBUTOR = 'distributor',
  MAINTENANCE = 'maintenance',
  PLUS = 'plus',
  EYE_OPENED = 'eye-opened',
  CLOCK = 'clock',
  OPTIONS = 'options',
  MENU = 'menu',
  MINUS = 'minus',
  NEW_BUSINESS = 'new-business',
  SORT_UP = 'sort-up',
  SORT_DOWN = 'sort-down',
  ARCHIVE = 'archive',
  EDIT = 'edit',
  PLAY = 'play',
  PLAY_CIRCLE = 'play-circle',
  DELETE = 'delete',
  LIST = 'list',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  EXCLAMATION = 'exclamation',
  REJECT = 'reject',
  MODIFY = 'modify',
  APPROVE = 'approve',
  EXCEPTION = 'exception'
}

export enum IconColor {
  BRAND_PRIMARY = 'brand-strong',
  BRAND_ACTION = 'brand-interactive',
  BRAND_DISABLED = 'brand-disabled'
}

export enum IconSizeModifier {
  REGULAR = 'icon--r',
  LARGE = 'icon--l',
  SMALL = 'icon--s'
}
