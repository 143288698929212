import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NextMenuItem } from '@wwtfTypes/menu.type';
import { NextActionHeaderTestIds } from '@components/organisms/layout/components/next-action-header/next-action-header.enum';

@Component({
  selector: 'next-action-header',
  templateUrl: './next-action-header.component.html',
  styleUrl: './next-action-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextActionHeaderComponent {
  @Input() label?: string;
  @Input() labelKey = '';
  @Input() actionList: NextMenuItem[] = [];
  protected readonly NextActionHeaderTestIds = NextActionHeaderTestIds;
}
