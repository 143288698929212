import { EndpointsConstant } from 'app/core/constants/endpoint.constant';

export class UserManagerEndpoints {

  static config = {
    LIST: {
      url: 'users'
    },
    EDIT_USER: {
      url: `users/${EndpointsConstant.genericIdParamSelector}`
    }
  };
}
