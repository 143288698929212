<div class="data-title"
     [class.data-title--horizontal-data]="isHorizontalData"
     [class.data-title--hidden]="hideField">
  <h3
    [ngClass]="['data-title__title', diffData ? 'data-title__data--modified' : ''] | appendText">{{title || (titleKey! | translate)}}</h3>
  <div class="data-title__data"
       [ngClass]="[
        dataFontSize ? dataFontSize : FontSizeModifier.REGULAR,
        isDataBold ? 'data-title__data--bold' : '',
        ] | appendText">
    <span>
      {{renderedData}}
    </span>
  </div>
</div>
