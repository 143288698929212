export enum PatternType {
    NO_EMPTY = '\\S+',
    NO_SPECIAL_CHARS = '[&<>"\']',
    NUMBER_DOTS_COMMAS = '^[-+]?(?:[0-9]+,)*[0-9]+(?:\\.[0-9]+)?$',
    NUMBER_MAX_TWO_DECIMALS = '^-?\\d+(\\.|,)?\\d{0,2}$',
    NUMBER = '^[0-9]*$',
    ALPHA_NUM = '^[a-zA-Z0-9]*$',
    ALPHA_NUM_SPACES = '^[a-zA-Z0-9 ]*$',
    PHONE = '^\\+*(\\d{3})*[0-9,\\-]{8,}',
}
