import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'next-message',
  templateUrl: './next-message.component.html',
  styleUrl: './next-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextMessageComponent {
  @Input() messages: Message[];
  @Input() enableService = false;
  @Input() closable = false;
}
