import { ChangeDetectionStrategy, Component, Injector, Input, signal } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { NavigationService } from '@services/navigation.service';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { GenericObject } from '@utils/models/Types';
import { RouteParams } from 'app/core/enums/route.enum';
import { filter } from 'rxjs';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabPageAbstractComponent<SectionType> extends GenericBaseComponent {
  @Input() id: any;
  sectionList: any[];
  sectionConfig: GenericObject<FormSection>;
  sectionConfigByPath: GenericObject<any> = {};

  mapSections: GenericObject;
  activeSectionSig: any;

  protected _navigationService: NavigationService;
  protected _router: Router;
  protected _activatedRoute: ActivatedRoute;

  constructor(injector: Injector) {
    super();
    this._navigationService = injector.get<NavigationService>(NavigationService);
    this._router = injector.get<Router>(Router);
    this._activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
  }

  initialize() {
    this._initializeStepControls();
    this._subscribeToNavigationChanges();
    const sectionIndex = this._findSectionToActivate();
    this._initializeActivateSection(sectionIndex);
  }

  setActiveSection(sectionId: any) {
    this.activeSectionSig.set(sectionId);
  }

  changeSection(sectionId: any) {
    this._navigationService
      .navigateToSibling(
        [NextObjectHelper.getPropertyFromObject(this.sectionConfig, [sectionId, FormSectionProps.PATH])],
        this._activatedRoute
      )
      .then();
  }

  handleChangeTab(idTab: number) {
    this.changeSection(this.sectionList[idTab]);
  }

  protected countList(list: any[]) {
    return list.length;
  }

  private _initializeStepControls() {
    this.mapSections = this.sectionList.reduce((acc, section, index) => {
      this.sectionConfigByPath[this.sectionConfig[section][FormSectionProps.PATH]] = {
        ...this.sectionConfig[section],
        [FormSectionProps.ID]: section
      };
      acc[section] = index;
      return acc;
    }, {});
  }

  private _subscribeToNavigationChanges() {
    this.subs.push(
      this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        const sectionIndex = this._findSectionToActivate();
        this.setActiveSection(this.sectionList[sectionIndex]);
      })
    );
  }

  private _findSectionToActivate() {
    const requestedSectionPath = NextObjectHelper.getPropertyFromObject(this._activatedRoute.snapshot.params, [
      RouteParams.STEP_FORM
    ]);
    const requestedSection = NextObjectHelper.getPropertyFromObject(
      this.sectionConfigByPath,
      [requestedSectionPath, FormSectionProps.ID],
      this.sectionList[0]
    );
    return this.mapSections[requestedSection];
  }

  private _initializeActivateSection(sectionIndex: number) {
    const section = this.sectionList[sectionIndex];
    this.activeSectionSig = signal<SectionType>(section);
  }
}
