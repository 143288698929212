import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FontSizeModifier } from '../../../../../core/enums/font.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { PipeConfig } from '../../../../../core/types/pipes.type';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { PipeListFormatPipe } from '@pipes/common/pipe-list-format.pipe';

@Component({
  selector: 'data-title',
  templateUrl: './data-title.component.html',
  styleUrl: './data-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTitleComponent implements OnInit {
  @Input() titleKey?: string;
  @Input() title?: string;
  @Input() data: any;
  @Input() dataRef?: any;
  @Input() dataPath?: any[];
  @Input() pipeList?: PipeConfig[] = [];
  @Input() isDataBold?: boolean = false;
  @Input() dataFontSize: FontSizeModifier = FontSizeModifier.REGULAR;
  @Input() subTitle?: string;
  @Input() isHorizontalData: boolean = false;
  @Input() fieldHidden: any = false;

  diffData: boolean;
  renderedData: any;
  hideField: boolean = false;

  protected readonly FontSizeModifier = FontSizeModifier;

  constructor(private _pipeListFormat: PipeListFormatPipe) {}

  ngOnInit(): void {
    this._setRenderData();
    this._transformData();
    this._fieldHidden();
  }

  private _setRenderData() {
    let data;
    let dataRef;
    
    if (this.dataPath) {
      data = NextObjectHelper.getPropertyFromObject(this.data, this.dataPath);
      dataRef = NextObjectHelper.getPropertyFromObject(this.dataRef, this.dataPath);
    } else {
      data = this.data;
      dataRef = this.dataRef;
    }
    this.diffData = NextValueHelper.isValueDefined(dataRef) && dataRef !== data;
    this.renderedData = data;
  }

  private _transformData() {
    if (this.pipeList) {
      this.renderedData = this._pipeListFormat.transform(this.renderedData, this.pipeList, this.data);
    }
  }

  private _fieldHidden() {
    if(this.fieldHidden) {
      this.hideField = !NextValueHelper.isValuePresent(this.data[this.fieldHidden])
    }
  }
}
