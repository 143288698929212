export enum NextGroupValidationKey {
  N_FILLED_FIELDS = 'nFilledFields'
}

export enum NextInputValidationKey {
  REQUIRED_NO_SPACES = 'requiredNoSpaces',
  REQUIRED_SECURITIES = 'requiredSecurities',
  ALL_STRING_ITEMS_EQUAL = 'allAreEqual',
  NUMERIC = 'numeric',
  EMAIL = 'email',
  MIN_LENGTH = 'minlength',
  MIN_LENGTH_NO_SPACES = 'minLengthNoSpaces',
  PATTERN = 'pattern',
  CUSTOM_PATTERN = 'customPattern',
  REQUIRED = 'required',
  UNIQUE_KEY = 'uniqueKey',
  SPECIAL_CHARS = 'specialChars',
  AMOUNT_VALIDATOR = 'allowNumberDotAndComma',
  MAX_LENGTH = 'maxlength',
  MAX_TWO_DECIMALS = 'maxTwoDecimals',
  MIN = 'min',
  MAX = 'max',
  MAX_CUSTOM = 'customMax',
  GREATER_THAN = 'greaterThan',
  CHARGE_ALREADY_EXISTS = 'chargeAlreadyExists',
  WRONG_RANGE = 'wrongRange',
  FISCAL_ID_NA = 'fiscalIdNA',
  PHONE_NO = 'phoneNo',
  IBAN = 'iban',
  NOT_START_WITH = 'notStartWith',
  LOWER_DATE = 'lowerDate',
  GREATER_DATE = 'greaterDate',
  SAME_DATE = 'sameDate',
  AMOUNT_NOT_MATCH = 'amountNotMatch'
}

export enum NextBankingValidationKey {
  IBAN = 'iban'
}

export enum AngularValidationKey {
  MAX_LENGTH = 'maxlength'
}
