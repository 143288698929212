import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '@components/atoms/buttons/components/button/enums/buttons.enum';

@Component({
  selector: 'bulk-actions-ribbon',
  templateUrl: './bulk-actions-ribbon.component.html',
  styleUrl: './bulk-actions-ribbon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkActionsRibbonComponent {
  @Input() actionLabelKey?: string = 'BUTTONS.SUBMIT';
  @Input() cancelLabelKey?: string = 'BUTTONS.CANCEL';
  @Input() approveLabelKey?: string = 'BUTTONS.APPROVE';
  @Input() isTaskboard: boolean = false;
  @Output() actionCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionApprove: EventEmitter<any> = new EventEmitter<any>();
  protected readonly ButtonType = ButtonType;
}
