import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { IconConstant } from '@components/atoms/visual/constants/icon.constant';

export class IconHelper {
  static createIconClass(
    iconId: NextIcon,
    color: IconColor = IconColor.BRAND_PRIMARY,
    iconSize: IconSizeModifier = IconSizeModifier.REGULAR
  ): any {
    if (!NextObjectHelper.isValueInObject(iconId, NextIcon)) {
      return;
    }

    const colorClass = this._addIconPrefix(color);

    return `${IconConstant.iconClassPrefix} ${IconConstant.iconClassPrefix}-${iconId} ${colorClass} ${iconSize}`;
  }

  private static _addIconPrefix(modifier: string): string {
    return modifier ? `${IconConstant.iconClassPrefix}--${modifier}` : '';
  }
}
