<p-inputNumber
  [formControl]="control"
  [currency]="currency"
  [useGrouping]="config.useGrouping!"
  [minFractionDigits]="config.minFractionDigits"
  [maxFractionDigits]="config.maxFractionDigits"
  [currencyDisplay]="config.currencyDisplay"
  [mode]="config.mode!"
  [locale]="locale"
  [styleClass]="'form__input'"
  (onInput)="inputChange.emit($event)"
>
</p-inputNumber>
