import { NextInputValidationKey } from '@utils/enums/validators.enum';
import { GenericObject } from '@utils/models/Types';
import { ValidationMessageConfigType } from '@components/atoms/form-inputs/components/validation-messages/validation-messages.type';

export class ValidationMessagesConstant {
  static readonly translationSettingsByError: GenericObject<ValidationMessageConfigType> = {
    [NextInputValidationKey.MIN_LENGTH]: {
      key: 'FORM.VALIDATION.MIN_CHARS'
    },
    [NextInputValidationKey.MIN_LENGTH_NO_SPACES]: {
      key: 'FORM.VALIDATION.MIN_CHARS'
    },
    [NextInputValidationKey.NUMERIC]: {
      key: 'FORM.VALIDATION.NUMERIC'
    },
    [NextInputValidationKey.PHONE_NO]: {
      key: 'FORM.VALIDATION.PHONE_NO'
    },
    [NextInputValidationKey.AMOUNT_VALIDATOR]: {
      key: 'FORM.VALIDATION.AMOUNT_NOT_VALID'
    },
    [NextInputValidationKey.ALL_STRING_ITEMS_EQUAL]: {
      key: 'FORM.VALIDATION.ALL_ARE_EQUAL'
    },
    [NextInputValidationKey.REQUIRED_NO_SPACES]: {
      key: 'FORM.VALIDATION.REQUIRED'
    },
    [NextInputValidationKey.MAX_TWO_DECIMALS]: {
      key: 'FORM.VALIDATION.MAX_TWO_DECIMALS'
    },
    [NextInputValidationKey.GREATER_THAN]: {
      key: 'FORM.VALIDATION.GREATER_THAN'
    },
    [NextInputValidationKey.PATTERN]: {
      key: 'FORM.VALIDATION.PATTERN'
    },
    [NextInputValidationKey.CUSTOM_PATTERN]: {
      key: 'FORM.VALIDATION.CUSTOM_PATTERN',
      hasParamTranslation: true
    },
    [NextInputValidationKey.NOT_START_WITH]: {
      key: 'FORM.VALIDATION.NOT_START_WITH',
      hasParamTranslation: true
    },
    [NextInputValidationKey.REQUIRED]: {
      key: 'FORM.VALIDATION.REQUIRED'
    },
    [NextInputValidationKey.UNIQUE_KEY]: {
      key: 'FORM.VALIDATION.UNIQUE_KEY'
    },
    [NextInputValidationKey.EMAIL]: {
      key: 'FORM.VALIDATION.EMAIL'
    },
    [NextInputValidationKey.MAX_LENGTH]: {
      key: 'FORM.VALIDATION.MAX_LENGTH'
    },
    [NextInputValidationKey.MIN]: {
      key: 'FORM.VALIDATION.MIN'
    },
    [NextInputValidationKey.MAX]: {
      key: 'FORM.VALIDATION.MAX'
    },
    [NextInputValidationKey.SPECIAL_CHARS]: {
      key: 'FORM.VALIDATION.NO_SPECIAL_CHARS'
    },
    [NextInputValidationKey.MAX_CUSTOM]: {
      key: 'FORM.VALIDATION.MAX_CUSTOM'
    },
    [NextInputValidationKey.CHARGE_ALREADY_EXISTS]: {
      key: 'FORM.VALIDATION.CHARGE_ALREADY_EXISTS'
    },
    [NextInputValidationKey.WRONG_RANGE]: {
      key: 'FORM.VALIDATION.WRONG_RANGE'
    },
    [NextInputValidationKey.FISCAL_ID_NA]: {
      key: 'FORM.VALIDATION.FISCAL_ID_NA'
    },
    [NextInputValidationKey.REQUIRED_SECURITIES]: {
      key: 'FORM.VALIDATION.REQUIRED_SECURITIES'
    },
    [NextInputValidationKey.LOWER_DATE]: {
      key: 'FORM.VALIDATION.LOWER_DATE',
      hasParamTranslation: true
    },
    [NextInputValidationKey.GREATER_DATE]: {
      key: 'FORM.VALIDATION.GREATER_DATE',
      hasParamTranslation: true
    },
    [NextInputValidationKey.SAME_DATE]: {
      key: 'FORM.VALIDATION.SAME_DATE',
      hasParamTranslation: true
    },
    [NextInputValidationKey.AMOUNT_NOT_MATCH]: {
      key: 'FORM.VALIDATION.AMOUNT_NOT_MATCH',
      hasParamTranslation: true
    }
  };
}
