<a (click)="handleLinkClick($event)">
  <next-badge *ngIf="hasExtraFilter"
             [colorType]="ColorType.BLUE" [isColorFilter]="true"></next-badge>
    <!-- <p-badge class="next-badge" *ngIf="hasExtraFilter"
        [class.next-badge--color-filter]="isColorFilter"
        [value]="value"
        [severity]="[colorType]="ColorType.BLUE"
        [ngClass]="BadgeConstant.classByColor[colorType]"></p-badge> -->
  <link-button  [iconType]="NextIcon.FILTER" 
              [iconSize]="IconSizeModifier.LARGE" ></link-button>
</a>