<p-button
  class="next-button"
  [label]="(labelKey | translate) || label"
  [disabled]="isDisabled"
  [rounded]="true"
  [severity]="severity"
  [outlined]="outlined"
  [styleClass]="sizeType"
  (click)="handleClick($event)"
></p-button>
