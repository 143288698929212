import { TimeCompareReference } from '../../core/enums/date.enum';
import { NextInputValidationKey } from '@utils/enums/validators.enum';

export class ValidationConstant {
  static readonly bicMaxLength = 11;
  static readonly bicMinLength = 8;
  static readonly phoneNumberMaxLength = 20;

  static dateComparedValidationKeyErrorByTimeReference = {
    [TimeCompareReference.BEFORE]: NextInputValidationKey.LOWER_DATE,
    [TimeCompareReference.AFTER]: NextInputValidationKey.GREATER_DATE,
    [TimeCompareReference.SAME]: NextInputValidationKey.SAME_DATE
  };
}
