import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormItem } from '@utils/models/Form';
import { ValidationMessagesConstant } from '@components/atoms/form-inputs/components/validation-messages/validation-messages.constant';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationMessagesComponent extends GenericBaseComponent implements OnInit, OnDestroy {
  @Input() control: FormItem;
  @Input() isAlignedLeft = false;

  settingsByError = ValidationMessagesConstant.translationSettingsByError;

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const controlChanges$ = merge(this.control.statusChanges, this.control.valueChanges);
    const subs = controlChanges$.pipe(debounceTime(500)).subscribe(() => this._cdr.markForCheck());
    this.subs.push(subs);
  }
}
