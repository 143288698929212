import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ButtonColor,
  ButtonSeverity,
  ButtonSize,
  ButtonType
} from '@components/atoms/buttons/components/button/enums/buttons.enum';
import { ButtonConstants } from '@components/atoms/buttons/components/button/constants/button.constants';

@Component({
  selector: 'next-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() isDisabled: boolean = false;
  @Input() sizeType: ButtonSize = ButtonSize.MEDIUM;
  @Input() type: ButtonType = ButtonType.PRIMARY;
  @Input() color: ButtonColor = ButtonColor.BLUE;

  @Output() clickEvent = new EventEmitter<any>();

  severity: ButtonSeverity | undefined;
  outlined: boolean;

  ngOnInit(): void {
    this._setButtonProperties();
  }

  handleClick(event?: MouseEvent): void {
    if (!this.isDisabled) {
      this.clickEvent.emit(event);
    }
  }

  private _setButtonProperties() {
    this.severity = ButtonConstants.severityByTpe[this.type];
    this.outlined = ButtonConstants.outlinedByType[this.type] || ButtonConstants.outlinedDefault;
  }
}
