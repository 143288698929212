import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';

@Component({
  selector: 'tab-title',
  templateUrl: './tab-title.component.html',
  styleUrl: './tab-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabTitleComponent {
  @Input() icon?: NextIcon;
  @Input() iconColor?: IconColor;
  @Input() iconSize?: IconSizeModifier;
  @Input() tabTitleStructureKey?: string = 'TABS.TITLE_QUANTITY';
  @Input() title: string;
  @Input() quantity?: number;
}
