export enum NavigationConfigProps {
  QUERY_PARAMS = 'queryParams',
  URL_PARAMS = 'urlParams',
  IS_NEW_TAB = 'isNewTab',
  IS_ABSOLUTE_URL = 'isAbsoluteUrl',
  ROUTE_PARENT = 'routeParent'
}

export enum NavSection {
  GENERAL_MAINTENANCE = 'generalMaintenance',
  DISTRIBUTOR_MANAGER = 'distributorManager',
  NEW_BUSINESS = 'newBusiness'
}

export enum NavItemId {
  USER = 'userManager',
  CURRENCY = 'currency',
  COUNTRY = 'country',
  BANKS = 'banks',
  DISTRIBUTOR_MANAGER = 'distributorManager',
  VEHICLE_ORDER = 'vehicleOrder',
  NEW_BUSINESS = 'newBusiness',
  SOURCE_LOCATION = 'sourceLocation'
}
