import { DateFromToControls } from '@components/atoms/form-inputs/components/date-from-to/date-from-to.enum';

export class DateFromToConstant {
  static readonly isEndOfByControlId: any = {
    [DateFromToControls.FROM]: false,
    [DateFromToControls.TO]: true
  };

  static readonly controlIdOpposite = {
    [DateFromToControls.FROM]: DateFromToControls.TO,
    [DateFromToControls.TO]: DateFromToControls.FROM
  };
}
