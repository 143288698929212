export enum NextPermission {
  READ = 'read',
  LIST = 'list',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum NextPermissionId {
  DISTRIBUTOR_GROUPS = 'distributorGroups',
  DISTRIBUTORS = 'distributors',
  USERS = 'users',
  CURRENCIES = 'currencies',
  COUNTRIES = 'countries',
  BANKS = 'bankSecurities',
  SOURCE_LOCATIONS = 'sourceLocations'
}
