import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';

@Component({
  selector: 'link-button',
  templateUrl: './link-button.component.html',
  styleUrl: './link-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkButtonComponent {
  @Input() label?: string;
  @Input() iconType: NextIcon;
  @Input() iconSize?: IconSizeModifier;
  @Input() iconPosition = LinkButtonPosition.RIGHT;
  @Input() iconColor?: IconColor = IconColor.BRAND_ACTION;
  @Input() isAction: boolean = true;
  @Input() isDisabled: boolean = false;

  @Output() clickEvt = new EventEmitter<Event>();
  protected readonly LinkButtonPosition = LinkButtonPosition;

  handleLinkClick(event: Event) {
    if (!this.isDisabled) {
      this.clickEvt.emit(event);
    }
  }
}
