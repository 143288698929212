export class RoutingConfigConstant {
  static readonly mainIdParam = 'id';
  static readonly mainIdParamRouting = `:${RoutingConfigConstant.mainIdParam}`;
  static readonly detailsPath = 'details';
  static readonly addNewPath = 'add-new';
  static readonly section = 'section';

  static readonly routeParamPrefix = ':';
  static readonly routeDataConfigProp = 'routeConfig';
}
