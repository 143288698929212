import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'next-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrl: './input-switch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSwitchComponent {
  @Input() control: any;
  @Input() name: string;
  @Output() inputSwitchClick: EventEmitter<any> = new EventEmitter<any>();
}
