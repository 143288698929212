<div
  *ngIf="control.dirty && control.errors"
  [class.validation-messages--left]="isAlignedLeft"
  class="validation-messages"
>
  <ng-container *ngFor="let error of control.errors | keyvalue">
    <p *ngIf="settingsByError[error.key] as config" class="validation-messages__item">
      {{ config.key | translateWrapper : error.value : config.hasParamTranslation }}
    </p>
  </ng-container>
  <ng-content select="[extraMessages]"></ng-content>
</div>
