import { Pipe, PipeTransform } from '@angular/core';
import { NextDateHelper } from '@utils/core/next-date.helper';

@Pipe({
  name: 'dateObject'
})
export class DateObjectPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return NextDateHelper.buildDateWithoutOffset(value);
    }
  }
}
