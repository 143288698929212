import { MINUTE } from '@constants/time.constant';
import packageInfo from '../../package.json';
import { EnvironmentConstant } from './environment.constant';

const BASE_URL = 'https://wwwdev.wwtfng.ford.com'; // 'https://wwtf-ng-ui-w3j3yfx4cq-uc.a.run.app';

export const environment = {
  production: false,
  appVersion: packageInfo.version,
  auth: {
    url: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
    logoutUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/logout',
    clientId: 'urn:wwtf:clientid:web_site_wwtf:dev',
    resourceId: 'urn:wwtf:resource:web_site_wwtf:dev',
    responseType: 'token+id_token',
    marginTokenExpire: 5 * MINUTE
  },
  BASE_URL,
  BASE_URL_API: EnvironmentConstant.devApiUrl,
  API_VERSION: 'v1',
  idleTime: 30 * MINUTE
};
