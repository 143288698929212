import { Pipe, PipeTransform } from '@angular/core';
import { TranslationWrapperService } from '../services/translation-wrapper.service';
import { GenericObject } from '@utils/models/Types';

@Pipe({
  name: 'translateWrapper'
})
export class TranslateWrapperPipe implements PipeTransform {
  constructor(private _translateService: TranslationWrapperService) {}

  transform(value: string, interpolateKeys?: GenericObject, isKeyTranslated = false, prefix?: string): any {
    let params = interpolateKeys;
    const mainKey = prefix ? `${prefix}.${value}` : value;

    if (!!interpolateKeys && isKeyTranslated) {
      params = Object.keys(interpolateKeys).reduce((acc: GenericObject, item) => {
        acc[item] = this._translateService.translateKey(interpolateKeys[item]);

        return acc;
      }, {});
    }

    return this._translateService.translateKey(mainKey, params);
  }
}
