export enum SecurityType {
  GUARANTORS = 'guarantors',
  INSURERS = 'insurers'
}

export enum GuarantorProps {
  ID = 'id',
  TYPE = 'type',
  NAME = 'name',
  STATUS = 'status',
  CURRENCY = 'currency',
  AMOUNT = 'backedCapital',
  EFFECTIVE_DATE = 'effectiveDate',
  EXPIRY_DATE = 'expiryDate'
}

export enum InsurerProps {
  ID = 'id',
  TYPE = 'type',
  EXPIRY_DATE = 'expiryDate',
  NAME = 'name',
  STATUS = 'status',
  AMOUNT = 'insuredCapital',
  CURRENCY = 'currency',
  EFFECTIVE_DATE = 'effectiveDate',
  COUNTRY_RISK_DEBTOR = 'countryRyskDebtor',
  INSURANCE_COUNTRY_RYSK = 'insuranceCountryRysk',
  STANDARD_LIMIT = 'standarLimit',
  COUNTRY_RISK_RATING = 'countryRyskRating',
  EXCEPTION_LIMIT = 'exceptionLimit',
  MOODYS_RATING = 'mooduysRating',
  SPS_RATING = 'spsRating'
}
