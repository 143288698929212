export enum FormStatus {
  DISABLED = 'DISABLED',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  VALID = 'VALID'
}

export enum FormConfigProps {
  DEFAULT_VALUE = 'defaultValue',
  FIXED_START_VALUE = 'fixedStartValue',
  IS_BOOLEAN = 'isBoolean',
  IS_DISABLED = 'isDisabled',
  IS_EXCLUDED = 'isExcluded',
  IS_LIST = 'isList',
  IS_REQUIRED = 'isRequired',
  VALIDATORS = 'validators',
  FOUR_EYE = 'fourEyeCheck',
  ENTITY_PATH = 'entityPath'
}
