export enum HttpConfigProps {
  SUCCESS_MSG = 'successMsgKey',
  SUCCESS_MSG_PARAMS = 'successMsgParams',
  CONCURRENCY_SEQ = 'concurrencySeq',
  OPTIONS = 'options',
  URL_PARAMS = 'urlParams',
  BODY = 'body',
  RESULT_WHIT_PROP = 'resultWithinProp'
}


export enum MsgProps {
  ENTITY = 'entity',
  SECTION_USER = 'User',
  SECTION_COUNTRY = 'Country',
  SECTION_BANK_SECURITY = 'Bank Security'
}