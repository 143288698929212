import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorType } from '../../../../../shared/enums/color.enum';
import { IconSizeModifier } from '@components/atoms/visual/enums/icon.enum';
import { BadgeConstant } from '@constants/badge.constant';

@Component({
  selector: 'next-tag',
  templateUrl: './next-tag.component.html',
  styleUrl: './next-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextTagComponent {
  @Input() value: string;
  @Input() severity?: any;
  @Input() size?: IconSizeModifier = IconSizeModifier.REGULAR;
  @Input() colorType: ColorType = ColorType.NEUTRAL;
  @Input() tagPositionRight: boolean = false;
  @Input() externalClass?: string;

  protected readonly BadgeConstant = BadgeConstant;
}
